import {FC, ReactElement} from "react";

type props = {
    search: string,
    text: string
};

export const Highlight: FC<props> = ({text, search}):ReactElement => {
    if (search.trim().length === 0) {
        return <>{text}</>;
    }
    const re = new RegExp(`(${search})`, 'gi');
    return <div dangerouslySetInnerHTML={{__html: text.replace(re, '<mark>$1</mark>')}}/>;
}
