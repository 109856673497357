import {FC} from "react";
import {WoodsaleData} from "../../models/woodsale.data";
import {Headline} from "../UI/headline/Headline";
import {usePartnerListQuery} from "../../configure/api/businesspartner-api";
import {$PartnerData} from "../../models/partner.data";
import {VSpace} from "../UI/spacer/Spacer";

type props = {
    data: WoodsaleData
};

export const ListOfSaleSummary: FC<props> = ({data}) => {
    const $partner = usePartnerListQuery();
    const brutto = data.price || 0;
    const netto = (data.price / 1.19) || 0;
    const tax = brutto - netto;
    const priceToPay = data.tax_shown ? brutto : netto;
    const discount = (priceToPay * (data.discount / 100)) || 0;
    const toBePaid = priceToPay - discount;
    const diff = (data.paid - priceToPay) || 0;

    const drawWho = () => {
        // @ts-ignore
        if (['unit', 'type', 'quantity'].every(key => (data[key] + '').trim().length > 0) && data.uid > 0) {
            // @ts-ignore
            const name = $PartnerData.getByUid($partner?.data, data.uid)?.["name"] ?? '';

            return <>
                <div>{name}: {data.quantity} {data.unit} {data.type}</div>
                <VSpace/></>;
        }
        return null;
    }

    const drawDifference = () => {
        if (diff === 0) {
            return <span>Betrag ausgeglichen.</span>;
        }
        if (data.paid === 0) {
            return <span>Noch nichts bezahlt..</span>;
        }

        return (
            <span>Es wurde <strong className={diff<0 ? 'color-error' : 'color-primary'}>{Math.abs(diff).toFixed(2)} EUR {diff < 0 ? 'zu wenig' : 'zu viel'}</strong> bezahlt.</span>
        );
    };

    return (
        <>
            <Headline>Zusammenfassung</Headline>
            <p>Hier siehst Du eine Zusammenfassung der Daten wie Du sie eingestellt hast.</p>

            {drawWho()}
            <div
                className="row">
                <span>Brutto</span>
                <span>{brutto?.toFixed(2) ?? 0} EUR</span>
            </div>
            {data.tax_shown && <div className="row">
               <span>enthaltene MwSt.</span><span>{tax?.toFixed(2) ?? 0} EUR</span></div>}
            <div className="row"><span>Skonto</span><span>{discount?.toFixed(2) ?? 0} EUR</span></div>
            <div
                className="row"><span>zu zahlender Betrag</span><span>{toBePaid?.toFixed(2) ?? 0} EUR</span></div>

            <VSpace/>
            <div className="row">
                {drawDifference()}
            </div>
            <VSpace/>
            <div className="row"><strong>Vorgang ist {data.done ? 'erledigt' : 'noch offen'}.</strong></div>
        </>
    );
}
