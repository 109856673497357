import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {$Http} from "../../utils/http";
import {BASEURL} from "./baseurl";

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASEURL,
        prepareHeaders: $Http.prepareHeaders,
    }),
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        dashboard: builder.query<any, void>({
            query: () => ({
                url: 'dashboard',
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useDashboardQuery,
} = dashboardApi;
