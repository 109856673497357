import {useState} from "react";
import {MultilineInput} from "../multilineinput/MultilineInput";
import {VSpace} from "../spacer/Spacer";
import {CancelButton, PrimaryButton} from "../button/Button";
import {useSendContactMutation} from "../../../configure/api/admin-api";
import {useLocation} from "react-router-dom";
import {Store} from "react-notifications-component";
import {$String} from "../../../utils/string";
import './Contact.css';

export const Contact = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('')
    const [$admin] = useSendContactMutation();
    const $route = useLocation();

    const send = () => {
        if (message.trim().length > 0) {
            $admin({message: $String.replacePseudo(message), route: $route.pathname}).then(() => {
                Store.addNotification({
                    message: "Daten gesendet",
                    type: "success",
                    container: "top-right",
                    dismiss: {
                        duration: 4000,
                    }
                });
            });

            setMessage('');
            setOpen(false);
        }
    };

    if (open) {
        return (
            <>
                <div className="contact--overlay"></div>
                <div className={"contact open"}>
                    <h1>Kontakt</h1>
                    <p>Du hast einen Fehler entdeckt oder hast einen Verbesserungsvorschlag? <br/>Der Link zur aktuellen
                        Seite (<strong>{$route.pathname}</strong>) wird automatisch übermittelt.</p>
                    <MultilineInput onChange={setMessage}
                                    initialValue={message}
                                    rows={10}/>
                    <VSpace/>
                    <div className="row end">
                        <CancelButton label={'Abbrechen'} onClick={() => setOpen(false)}/>
                        <PrimaryButton label={'Abschicken'} onClick={send}/>
                    </div>
                </div>
            </>);
    }

    return (<div className={"contact"} onClick={() => setOpen(true)} aria-hidden>
        <svg width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
             strokeWidth="1" viewBox="0 0 24 24">
            <path d="M22 3H2v15h4.5v2.5l5-2.5H22V3Z"></path>
            <path d="M7 9.75v1.5"></path>
            <path d="M12 9.75v1.5"></path>
            <path d="M17 9.75v1.5"></path>
        </svg>
    </div>);
};
