import {useWoodSalesMetaQuery} from "../../configure/api/wood-api";
import {Headline} from "../UI/headline/Headline";
import {FC,} from "react";
import {VSpace} from "../UI/spacer/Spacer";
import {$WoodSale, FilterPreset, FilterWood} from "../../models/woodsale.data";
import {useSelector} from "react-redux";
import {selectUser} from "../../configure/slice/auth-slice";
import {DateRange} from "../UI/datepicker/DateRange";
import {YesNo} from "../UI/yesno/YesNo";
import {endOfMonth, startOfMonth, subMonths} from "date-fns";
import {Link} from "../UI/link/Link";

type props = {
    filter: FilterWood,
    onFilter: (val: FilterWood) => void
};

export const ListOfSales: FC<props> = ({filter, onFilter}) => {
    const $meta = useWoodSalesMetaQuery();
    const $user = useSelector(selectUser);

    const now = new Date();

    const minYear = new Date(($meta?.data?.minDate ?? 0)).getFullYear();
    const years = Array(new Date().getFullYear() - minYear + 1).fill(0).map((yr, index) => minYear + index);

    const presets: FilterPreset[] = [
        $WoodSale.preset('Schnellauswahl', 0, 0, false),
        $WoodSale.preset('Aktueller Monat', startOfMonth(now).getTime(), endOfMonth(now).getTime(), true),
        $WoodSale.preset('Vorheriger Monat', startOfMonth(subMonths(now, 1)).getTime(), endOfMonth(subMonths(now, 1)).getTime(), true),
        $WoodSale.preset('Alle offenen Belege', $meta?.data?.minDate, $meta?.data?.maxDate, false),
        $WoodSale.preset('Quartale dieses Jahr', 0, 0, false),
        $WoodSale.preset('Q1', new Date(now.getFullYear(), 0, 1).getTime(), new Date(now.getFullYear(), 2, 31).getTime(), true),
        $WoodSale.preset('Q2', new Date(now.getFullYear(), 3, 1).getTime(), new Date(now.getFullYear(), 5, 30).getTime(), true),
        $WoodSale.preset('Q3', new Date(now.getFullYear(), 6, 1).getTime(), new Date(now.getFullYear(), 8, 30).getTime(), true),
        $WoodSale.preset('Q4', new Date(now.getFullYear(), 9, 1).getTime(), new Date(now.getFullYear(), 11, 31).getTime(), true),
        $WoodSale.preset('Jahresauswahl', 0, 0, false),
        ...years.map(yr => $WoodSale.preset(`Jahr ${yr}`, new Date(yr, 0, 1).getTime(), new Date(yr, 11, 31).getTime(), true))
    ];

    const drawPreset = () => (
        <>
            {presets.map((item: FilterPreset) => (
                (item.from===0 && item.to===0) ? <p key={item.label}><strong>{item.label}</strong></p> :
                <Link onClick={() => onFilter(item)} key={item.label}>{item.label}</Link>
            ))}
        </>
    );

    return (
        <>
            <Headline>Belegsuche</Headline>
            <p>Wähle hier einen Bereich aus, für den die Belege angezeigt werden sollen.</p>

            {$user.canEdit &&
               <div className={"row end"}>
                  <a href="/wood/new">Beleg anlegen</a>
               </div>}
            <VSpace/>

            <DateRange label={'Datumsbereich'} from={filter.from} to={filter.to} onChange={(start, end) => {
                onFilter({...filter, from: start, to: end});
            }
            }/>

            <VSpace/>
            <YesNo initialValue={filter.done} label={'Beleg erledigt?'}
                   onChange={(done: boolean) => onFilter({...filter, done})}/>
            <VSpace/>
            <p>...oder wähle hier einen Vordefinierten Filter aus.</p>
            {drawPreset()}
        </>);
}
