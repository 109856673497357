import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import {Provider} from 'react-redux';
import {SignIn} from './routes/sign-in/SignIn';
import {persistor, store} from "./configure/configure";
import {Dashboard} from "./routes/dashboard/Dashboard";
import {Partner} from "./routes/partner/Partner";
import {History} from "./routes/history/History";
import {ErrorHandler} from "./routes/error-handler/ErrorHandler";
import {PersistGate} from "redux-persist/integration/react";
import {Quota} from "./routes/quota/Quota";
import {QuotaEdit} from "./routes/quota/quota-edit/QuotaEdit";
import {PartnerInfo} from "./routes/partner/partner-info/PartnerInfo";
import {Changelog} from "./routes/changelog/Changelog";
import {Wood} from "./routes/wood/Wood";
import {Profile} from "./routes/profile/Profile";
import {WoodDetails} from "./routes/wood/wood-details/WoodDetails";

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<SignIn/>}/>
                        <Route path="dashboard" element={<Dashboard/>}/>

                        <Route path="partner/:uid" element={<Partner/>}/>
                        <Route path="partner/:uid/info" element={<PartnerInfo/>}/>
                        <Route path="partner" element={<Partner/>}/>

                        <Route path="quota" element={<Quota/>}/>
                        <Route path="quota/:uid/edit/*" element={<QuotaEdit/>}/>

                        <Route path="history" element={<History/>}/>

                        <Route path="wood" element={<Wood/>}/>
                        <Route path="wood/:uid" element={<WoodDetails/>}/>

                        <Route path="profile" element={<Profile/>}/>

                        <Route path="changelog" element={<Changelog/>}/>
                    </Routes>
                    <ErrorHandler/>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

export default App;
