import {FC, useEffect, useState} from "react";
import {FieldsData} from "../../models/fields.data";
import {MultilineInput} from "../UI/multilineinput/MultilineInput";
import {Input} from "../UI/input/Input";
import {Datepicker} from "../UI/datepicker/Datepicker";
import {Dropdown} from "../UI/dropdown/Dropdown";
import {VSpace} from "../UI/spacer/Spacer";
import {CancelOrSave} from "../cancel-or-save/CancelOrSave";
import {YesNo} from "../UI/yesno/YesNo";

export type UpdateFunction = (key: string, value: any) => void;
export type ValidationFunction = (value: any) => string | undefined;

export interface ValidationData {
    [key: string]: ValidationFunction
}

/* validation routines */
export const validateFilled = (value: string, error: string) => (value ?? '').trim().length === 0 ? error : undefined;
export const validateNumber = (value: any, min?: number, max?: number) => {
    const inp = parseFloat(value);
    if (Number.isNaN(inp)) {
        return 'Bitte eine Zahl eingeben.';
    }
    if (min !== undefined && inp < min) {
        return `Wert muss mindestens ${min} sein.`;
    }
    if (max !== undefined && inp > max) {
        return `Wert darf höchstens ${max} sein.`;
    }
    return undefined;
};

interface ValidationResult {
    [key: string]: string
}

type props = {
    fields: FieldsData[],
    validator?: ValidationData,
    data: any,
    onUpdate: UpdateFunction,
    submitText?: string,
    onSubmit: (flag: boolean) => void
};

export const FormFields: FC<props> = ({fields, data, validator = {}, submitText, onUpdate, onSubmit}) => {
    const [violations, setViolations] = useState<ValidationResult>({});

    const updateHandler = (key: string) => (value: any) => {
        const type = (typeof data[key]);
        switch (type) {
            case 'number':
                onUpdate(key, parseFloat(value));
                break;
            case 'string':
                onUpdate(key, value+'');
                break;
            case 'boolean':
                onUpdate(key, !!value);
                break;
        }
    };

    const validate = () => {
        const list: ValidationResult = {};
        Object.keys(validator).forEach((key: string) => {
            const value = data[key];
            const result = validator[key](value);
            if (result !== undefined) {
                list[key] = result;
            }
        });
        // check if something has changed
        const source = Object.keys(list).sort().join(' ');
        const dest = Object.keys(violations).sort().join(' ');
        if (source !== dest) {
            setViolations(list);
        }
        console.groupEnd();
    };

    useEffect(validate, [validator, data]);

    const drawField = (field: FieldsData, index: number) => (
        <div className={`width-${field.width}`} key={`field-${field.attribute}-${index}`}
             style={{paddingRight: field.width === 100 ? 0 : (field.padding ? 0 : '0.5rem')}}>
            {field.type==='label' &&
               <div><small className={'color-primary'}>{field.label}</small><br/>{data[field.attribute]}</div>
            }
            {field.type === 'multi' &&
               <MultilineInput label={field.label}
                               initialValue={data[field.attribute]}
                               onChange={updateHandler(field.attribute)}/>}
            {(field.type === "text" || field.type === 'password') &&
               <Input label={field.label}
                      initialValue={data[field.attribute]}
                      type={field.type}
                      error={violations?.[field.attribute]}
                      onChange={updateHandler(field.attribute)}/>
            }
            {field.type === 'date' &&
               <Datepicker label={field.label}
                           initialValue={data[field.attribute]}
                           onChange={updateHandler(field.attribute)}/>
            }
            {field.type === 'select' &&
               <Dropdown label={field.label}
                         initialValue={data[field.attribute]}
                         list={field.typeData}
                         error={violations?.[field.attribute]}
                         onChange={updateHandler(field.attribute)}/>
            }
            {field.type === 'yesno' &&
               <YesNo label={field.label} initialValue={data[field.attribute]}
                      onChange={updateHandler(field.attribute)}/>
            }
            {field.type === 'separator' &&
               <VSpace/>}

            {field.padding && <VSpace value={0.5}/>}
        </div>
    );

    return (
        <form >
            <div className="row start wrap">
                {
                    fields.map(drawField)
                }
            </div>
            <VSpace/>
            <CancelOrSave disabled={Object.keys(violations).length > 0} saveText={submitText} onSave={onSubmit}/>
        </form>
    );
};
