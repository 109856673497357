import {CancelButton, PrimaryButton} from "../UI/button/Button";
import {FC, ReactElement} from "react";

type props = {
    disabled?: boolean,
    saveText?: string,
    onSave: (flag: boolean) => void
};

export const CancelOrSave: FC<props> = ({disabled = false, saveText, onSave}): ReactElement => (
    <div className="row vcenter end">
        <CancelButton label={'Abbrechen'} onClick={() => onSave(false)}/>
        <PrimaryButton label={saveText ?? 'Speichern'} disabled={disabled} onClick={() => onSave(true)}/>
    </div>
);
