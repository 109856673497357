import React, {Fragment} from "react";
import {useParams} from "react-router-dom";
import logo from '../../../assets/logo.jpg';
import {format} from "date-fns";
import {de} from "date-fns/locale";
import {$String} from "../../../utils/string";
import {VSpace} from "../../../components/UI/spacer/Spacer";
import {$Quota} from "../../../models/quota.data";
import {useCollectPartnerInfoQuery} from "../../../configure/api/businesspartner-api";
import {$PartnerData} from "../../../models/partner.data";
import './PartnerInfo.css';

export const PartnerInfo = () => {
    const params = useParams();
    const id = (params.uid === undefined) ? -1 : parseInt(params.uid, 10);
    const $info = useCollectPartnerInfoQuery(id, {skip: id === -1});

    const customer = $info?.data?.customer;
    const quota = $info?.data?.quota ?? {};
    const keys = Object.keys(quota);

    const drawQuotaItem = (key: string) => (item: any) => {
        const markName = `Mark ${key}${item.mid}`;
        const more = item.more && item.more > 1 ? ` (+${item.more})` : '';

        return <div key={item.mid}><span className="value">{$Quota.displayMarkQuota(item.quota)}</span> Anteile
            an {markName}{more}</div>
    };

    const drawUsufruct = () => {
        const usu = $info.data?.usufruct ?? null;
        if (usu === null) {
            return null;
        }

        return (
            <>
                {usu.external?.map((item: any) => (
                    <div key={item.mid}><strong>{item.name}</strong> hat Nießbrauchsrecht an dem Anteil {item.mid}
                    </div>))}
                {usu.self?.map((item: any) => (
                    <div key={item.mid}><strong>{$String.combine(customer, 'firstname lastname')}</strong> hat
                        Nießbrauchsrecht an dem Anteil {item.mid} von <strong>{item.name}</strong></div>))}
            </>
        );
    };

    const isMaerkner = $PartnerData.isMaerkner(customer);

    const drawContact = () => {
        if (isMaerkner) {
            return (
                <>

                    <div className="key--value">
                        <div className="key bold">Tel</div>
                        {customer?.phone || '---'}
                    </div>
                    <div className="key--value">
                        <div className="key bold">Fax</div>
                        {customer?.phone_fax || '---'}
                    </div>
                    <div className="key--value">
                        <div className="key bold">Mobil</div>
                        {customer?.phone_mobile || '---'}
                    </div>
                    <div className="key--value">
                        <div className="key bold">E-Mail</div>
                        {customer?.email || '---'}
                    </div>
                </>
            );
        }

        return (<div className="key--value">
            <div className="key bold">Web</div>
            {customer?.website || '---'}
        </div>);
    };


    const drawQuota = () => {
        if (keys.length === 0) {
            return null;
        }
        return (
            <>

                {
                    keys.map((key: string) => (
                            <Fragment key={key}>
                                <strong>{$Quota.MARK_MAPPING[key]}</strong><br/>
                                {quota[key].map(drawQuotaItem(key))}
                                <VSpace/>
                            </Fragment>
                        )
                    )
                }

                <VSpace/>
                <div className="customer--info-separator"/>
                <VSpace/>
            </>);
    };

    return (
        <div className={'customer--info'}>
            <div className="customer--info-logo">
                <img src={logo} alt="logo"/>
                <div className="text">Markenverband Winterberg</div>
            </div>

            <VSpace value={2}/>

            <div className="customer--info-content">
                <>
                {isMaerkner && <>{customer?.salutation}<br/></>}
                <div
                    className={'bold bigger'}>{$String.combine(customer, isMaerkner ? 'firstname lastname' : 'name')}</div>
                <VSpace/>
                <div>
                    {$String.combine(customer, 'street')}<br/>
                    {$String.combine(customer, 'plz city')}
                </div>
                <VSpace/>
                <div className="customer--info-separator"/>
                <VSpace/>

                {drawContact()}
                <VSpace/>
                <div className="customer--info-separator"/>
                <VSpace/>
                {$String.replacePseudo(customer?.note || 'Keine Notiz')}
                <VSpace/>
                <div className="customer--info-separator"/>
                <VSpace/>

                {drawQuota()}
                {drawUsufruct()}

                <VSpace/>
                <p className={"smaller"}>Automatisch erstellt
                    am {format(new Date(), 'dd. MMMM yyyy', {locale: de})}</p>
                </>
            </div>
        </div>
    );

};
