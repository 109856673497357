import {FC} from "react";
import './FilterSelect.css';

type props = {
    list: string[] | number[],
    labels?: string[],
    active: string | number,
    onChange: (value: any) => void,
    vertical?: boolean,
    addEmpty?: boolean
}

export const FilterSelect: FC<props> = ({list, labels = [], addEmpty = false, vertical = false, active, onChange}) => (
    <>
        {addEmpty && <div className={`filter--select-item ${active === '' ? 'active' : ''} ${vertical ? 'block' : ''}`}
                          key={'empty'}
                          aria-hidden
                          onClick={() => onChange('')}>Keine Auswahl</div>
        }
        {
            list.map((item: string | number, index: number) => (
                <div className={`filter--select-item ${active === item ? 'active' : ''} ${vertical ? 'block' : ''}`}
                     key={item}
                     aria-hidden
                     onClick={() => onChange(item)}>{labels[index] ?? item}</div>))
        }
    </>
);
