import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {actionAuth, selectUser} from "../../configure/slice/auth-slice";
import {Help} from "../help/Help";
import './Menu.css';

export const Menu = () => {
    const $user = useSelector(selectUser);
    const [help, setHelp] = useState(false);
    const defSize = {width: 24, height: 24};
    const $route = useLocation();
    const dispatch = useDispatch();

    const isActive = (path: string): boolean => ($route.pathname.indexOf(path) === 0);

    return (
        <nav className={"menu"}>
            <Link to={'/dashboard'} aria-label="Dashboard">
                <div className={isActive('/dashboard') ? 'active' : ''}>
                    <svg viewBox="0 0 24 24" {...defSize}>
                        <g fill="currentColor">
                            <path
                                d="M10 20v2.5c0 .27-.23.5-.5.5h-8c-.28 0-.5-.23-.5-.5l0-5c0-.28.22-.5.5-.5h8c.27 0 .5.22.5.5V20Zm1 0v-2.5c0-.83-.68-1.5-1.5-1.5h-8c-.83 0-1.5.67-1.5 1.5l-.001 5c0 .82.67 1.5 1.5 1.5h8c.82 0 1.5-.68 1.5-1.5V20Z"/>
                            <path
                                d="M23 17v5.5c0 .27-.23.5-.5.5h-8c-.28 0-.5-.23-.5-.5v-11c0-.28.22-.5.5-.5h8c.27 0 .5.22.5.5V17Zm1 0v-5.5c0-.83-.68-1.5-1.5-1.5h-8c-.83 0-1.5.67-1.5 1.5v11c0 .82.67 1.5 1.5 1.5h8c.82 0 1.5-.68 1.5-1.5V17Z"
                                transform="rotate(180 18.5 17)"/>
                            <path
                                d="M23 4v2.5c0 .27-.23.5-.5.5l-8 0c-.28 0-.5-.23-.5-.5v-5c0-.28.22-.5.5-.5h8c.27 0 .5.22.5.5V4Zm1 0V1.5c0-.83-.68-1.5-1.5-1.5h-8c-.83-.001-1.5.67-1.5 1.5v5c0 .82.67 1.5 1.5 1.5h8c.82 0 1.5-.68 1.5-1.5V4Z"
                                transform="rotate(180 18.5 4)"/>
                            <path
                                d="M10 7v5.5c0 .27-.23.5-.5.5l-8 0c-.28 0-.5-.23-.5-.5l0-11c0-.28.22-.5.5-.5h8c.27 0 .5.22.5.5V7Zm1 0V1.5C11 .67 10.32 0 9.5 0h-8C.67-.001 0 .67 0 1.5l-.001 11c0 .82.67 1.5 1.5 1.5l8-.001c.82 0 1.5-.68 1.5-1.5v-5.5Z"/>
                        </g>
                    </svg>
                </div>
            </Link>
            <Link to={'/partner'} aria-label="Geschäftspartner">
                <div className={isActive('/partner') ? 'active' : ''}>
                    <svg viewBox="0 0 24 24" {...defSize}>
                        <g fill="currentColor">
                            <path
                                d="M20 10.5c0 5.24-4.26 9.5-9.5 9.5C5.25 20 1 15.74 1 10.5 1 5.25 5.25 1 10.5 1c5.24 0 9.5 4.25 9.5 9.5Zm1 0C21 4.7 16.29 0 10.5 0 4.7 0 0 4.7 0 10.5 0 16.29 4.7 21 10.5 21 16.29 21 21 16.29 21 10.5Z"/>
                            <path
                                d="M23.85 23.14l-5.93-5.93c-.2-.2-.52-.2-.71 0 -.2.19-.2.51 0 .7l5.93 5.93c.19.19.51.19.7 0 .19-.2.19-.52 0-.71Z"/>
                        </g>
                    </svg>
                </div>
            </Link>
            <Link to={'/quota'} aria-label="Anteile">
                <div className={isActive('/quota') ? 'active' : ''}>
                    <svg viewBox="0 0 24 24" {...defSize}>
                        <g fill="currentColor">
                            <path
                                d="M13 10c0 .55.44 1 1 1h9.5c.27 0 .5-.23.5-.5C24 4.7 19.29 0 13.5 0c-.28 0-.5.22-.5.5V10Zm1 0V.5l-.5.5c5.24 0 9.5 4.25 9.5 9.5l.5-.5H14s0 0 0 0Z"/>
                            <path
                                d="M20.92 19.92c1.97-1.97 3.07-4.65 3.07-7.43 -.01-.28-.23-.5-.5-.5h-9.5c-.72 0-1.01.69-.51 1.2l6.72 6.71c.19.19.51.19.7 0Zm-.71-.71l.7 0 -6.73-6.72c.12.12-.04.5-.21.5h9.5l-.5-.5c0 2.51-1 4.93-2.78 6.71Z"/>
                            <path
                                d="M10.5 3C4.7 2.99-.01 7.7-.01 13.49c-.01 5.79 4.7 10.5 10.49 10.5 2.78 0 5.45-1.11 7.42-3.08 .19-.2.19-.52-.01-.71l-6.72-6.718c-.12-.14-.2-.33-.21-.53l0-9.48c0-.28-.23-.5-.5-.5Zm-.01 1l-.5-.5V13c.02.45.19.86.48 1.18l6.73 6.73 -.01-.71c-1.79 1.78-4.2 2.78-6.72 2.78 -5.25-.01-9.5-4.26-9.5-9.51s4.25-9.5 9.5-9.5Z"/>
                        </g>
                    </svg>
                </div>
            </Link>
            <Link to={'/wood'} aria-label="Holzverkäufe">
                <div className={isActive('/wood') ? 'active' : ''}>
                    <svg viewBox="0 0 24 24"  {...defSize}>
                        <g fill="currentColor">
                            <path
                                d="M12.3 5.64L2.12 15.81c-.13.12-.33.12-.46 0 -.01-.01-.01-.01-.01-.01l-.59-.58c-.13-.13-.13-.33 0-.46l10.17-10.18c.19-.2.19-.52-.01-.71 -.2-.2-.52-.2-.71 0L.33 14.04c-.52.51-.52 1.35-.01 1.86l.58.57c-.01-.01-.01-.01 0 0 .51.51 1.35.51 1.86-.01l10.17-10.18c.19-.2.19-.52-.01-.71 -.2-.2-.52-.2-.71 0Z"/>
                            <path
                                d="M15.08 3.85l3.37 1.3s0 0 0 0c-.26 1.05-.78 2.02-1.53 2.82 -.68.67-1.56 1.11-2.51 1.26 0-.01 0-.01 0-.01L13.09 5.8l-.12-.18 -2.62-2.61c-.13-.13-.13-.33 0-.46L11.8 1.1c.12-.13.32-.13.45-.01l2.6 2.6 .17.11Zm.35-.94l.17.11L12.99.41c-.52-.52-1.36-.52-1.87-.01L9.66 1.84c-.52.51-.52 1.35-.01 1.86l2.61 2.61 -.12-.18 1.31 3.41c.16.43.6.69 1.06.63 1.17-.18 2.24-.73 3.07-1.56 .87-.93 1.48-2.06 1.78-3.29 .11-.49-.15-.99-.62-1.17l-3.38-1.3Z"/>
                            <path
                                d="M13.87 2l.87-.87c.12-.13.32-.13.45-.01l.58.57c.12.12.12.32-.01.45l-.88.86c-.2.19-.2.51-.01.7 .19.19.51.19.7 0l.87-.87c.51-.52.51-1.36 0-1.87l-.59-.59c-.52-.52-1.36-.52-1.87-.01l-.88.86c-.2.19-.2.51-.01.7 .19.19.51.19.7 0Z"/>
                            <path
                                d="M19.55 15.72l-.53 3.65 .49-.43h-3.7c-.42-.01-.76.33-.76.75 0 .01 0 .01 0 .03 .15 2.47 2.28 4.34 4.74 4.18 2.46-.16 4.33-2.28 4.17-4.75 -.13-2.01-1.58-3.68-3.54-4.09 -.42-.09-.82.18-.9.59 -.01.02-.01.02-.01.04Zm.99.14c-.01 0-.01 0-.01.01 -.03.13-.16.21-.29.19 1.51.32 2.63 1.61 2.73 3.17 .12 1.91-1.34 3.56-3.25 3.68 -1.92.12-3.57-1.34-3.69-3.25 0 .01 0 .01 0 .02 -.01.13-.11.24-.25.24l3.7 0c.24 0 .45-.19.49-.43l.52-3.66Z"/>
                            <path
                                d="M9 23.85l3.84-.54c.25-.04.44-.27.43-.53 -.12-1.76-1.28-3.28-2.94-3.85 -.25-.09-.53.03-.63.27l-1.513 3.6c-.16.38.02.82.4.98 .12.05.25.06.39.04Zm-.14-1c.04-.01.08-.01.13.01 .12.05.18.19.13.32l1.51-3.6 -.63.27c1.28.44 2.17 1.61 2.26 2.96l.43-.53 -3.85.53Z"/>
                            <path
                                d="M18.01 16.6l-.83-3.8c-.06-.26-.3-.43-.56-.39 -1.75.24-3.17 1.51-3.62 3.21 -.07.25.07.51.32.6l3.69 1.23c.38.13.81-.08.94-.47 .04-.13.05-.27.02-.4Zm-.98.2c-.01-.05-.01-.1 0-.14 .04-.14.18-.2.31-.16l-3.71-1.24 .32.6c.34-1.32 1.44-2.3 2.78-2.49l-.56-.39 .82 3.8Z"/>
                        </g>
                    </svg>
                </div>
            </Link>

            {$user.canEdit &&
               <Link to={'/history'} aria-label="Protokolldaten">
                  <div className={isActive('/history') ? 'active' : ''}>
                     <svg viewBox="0 0 24 24" {...defSize}>
                        <g fill="currentColor">
                           <path
                              d="M19.021 4.501c0 1.81-4.01 3.5-9 3.5 -5 0-9-1.69-9-3.5 0-1.82 4-3.5 9-3.5 4.99 0 9 1.68 9 3.5Zm1 0c0-2.61-4.51-4.5-10-4.5 -5.5 0-10 1.89-10 4.5 0 2.6 4.5 4.5 10 4.5 5.49 0 10-1.9 10-4.5Z"/>
                           <path
                              d="M11.48 11.95c-.49.03-.98.04-1.47.04 -5 0-9-1.69-9-3.5 0-.28-.23-.5-.5-.5 -.28 0-.5.22-.5.5 0 2.6 4.5 4.5 10 4.5 .51 0 1.02-.02 1.53-.06 .27-.02.48-.26.46-.54 -.02-.28-.26-.49-.54-.47Z"/>
                           <path
                              d="M10.02 16.5c-5 0-9-1.69-9-3.5 0-.28-.23-.5-.5-.5 -.28 0-.5.22-.5.5 0 2.6 4.5 4.5 10 4.5 .27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5Z"/>
                           <path
                              d="M10.02 21c-5 0-9-1.69-9-3.5v-13c0-.28-.23-.5-.5-.5 -.28 0-.5.22-.5.5v13c0 2.6 4.5 4.5 10 4.5 .27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5Z"/>
                           <path
                              d="M19.02 4.5V10c0 .27.22.5.5.5 .27 0 .5-.23.5-.5V4.5c0-.28-.23-.5-.5-.5 -.28 0-.5.22-.5.5Z"/>
                           <path
                              d="M21.078 17.03c0 2.22-1.81 4.029-4.029 4.029 -2.23 0-4.029-1.81-4.029-4.029 0-2.23 1.8-4.029 4.029-4.029 2.22 0 4.029 1.8 4.029 4.029Zm1 0c0-2.78-2.26-5.029-5.029-5.029 -2.78 0-5.029 2.25-5.029 5.029 0 2.77 2.25 5.029 5.029 5.029 2.77 0 5.029-2.26 5.029-5.029Z"/>
                           <path
                              d="M23.87 23.14l-3.25-3.25c-.2-.2-.52-.2-.71 0 -.2.19-.2.51 0 .7l3.25 3.25c.19.19.51.19.7 0 .19-.2.19-.52 0-.71Z"/>
                        </g>
                     </svg>
                  </div>
               </Link>}
            <Link to={'/changelog'} aria-label="Änderungshistorie">
                <div className={isActive('/changelog') ? 'active' : ''}>
                    <svg viewBox="0 0 24 24" {...defSize}>
                        <g fill="currentColor">
                            <path
                                d="M15 9.5v-6c0-.83-.68-1.5-1.5-1.5h-7c-.16 0-.31-.08-.4-.2L5.2.6C4.91.22 4.47 0 4 0H1.5c-.83 0-1.5.67-1.5 1.5v9c0 .82.67 1.5 1.5 1.5h6c.27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5h-6c-.28 0-.5-.23-.5-.5v-9c0-.28.22-.5.5-.5H4c.15 0 .3.07.4.2l.9 1.2c.28.37.72.6 1.2.6h7c.27 0 .5.22.5.5v6c0 .27.22.5.5.5 .27 0 .5-.23.5-.5Z"/>
                            <path
                                d="M22.5 14h-7c-.16 0-.31-.08-.4-.2l-.9-1.2c-.29-.38-.73-.6-1.2-.6h-2.5c-.83 0-1.5.67-1.5 1.5v9c0 .82.67 1.5 1.5 1.5h12c.82 0 1.5-.68 1.5-1.5v-7c0-.83-.68-1.5-1.5-1.5Zm0 1c.27 0 .5.22.5.5v7c0 .27-.23.5-.5.5h-12c-.28 0-.5-.23-.5-.5v-9c0-.28.22-.5.5-.5H13c.15 0 .3.07.4.2l.9 1.2c.28.37.72.6 1.2.6h7Z"/>
                            <path
                                d="M24 7.5V6c0-1.11-.9-2-2-2h-5c-.28 0-.5.22-.5.5 0 .27.22.5.5.5h5c.55 0 1 .44 1 1v1.5c0 .27.22.5.5.5 .27 0 .5-.23.5-.5Z"/>
                            <path
                                d="M18.64 2.14l-2 2c-.2.19-.2.51 0 .7l2 2c.19.19.51.19.7 0 .19-.2.19-.52 0-.71l-2-2v.7l2-2c.19-.2.19-.52 0-.71 -.2-.2-.52-.2-.71 0Z"/>
                            <path
                                d="M0 16.5V18c0 1.1.89 2 2 2h5c.27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5H2c-.56 0-1-.45-1-1v-1.5c0-.28-.23-.5-.5-.5 -.28 0-.5.22-.5.5Z"/>
                            <path
                                d="M5.35 21.85l2-2c.19-.2.19-.52 0-.71l-2-2c-.2-.2-.52-.2-.71 0 -.2.19-.2.51 0 .7l2 2v-.71l-2 2c-.2.19-.2.51 0 .7 .19.19.51.19.7 0Z"/>
                        </g>
                    </svg>
                </div>
            </Link>

            <button onClick={()=>setHelp(true)} type="button" aria-label="Hilfe">
                <svg viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"
                     {...defSize}>
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                </svg>
            </button>

            <Link to={'/'} className={'end'} onClick={() => dispatch(actionAuth.reset())} aria-label="Abmelden">
                <svg viewBox="0 0 24 24" {...defSize}>
                    <g fill="currentColor">
                        <path
                            d="M23.5 11.5h-12c-.28 0-.5.22-.5.5 0 .27.22.5.5.5h12c.27 0 .5-.23.5-.5 0-.28-.23-.5-.5-.5Z"/>
                        <path
                            d="M19.85 16.35l4-4c.19-.2.19-.52 0-.71l-4-4c-.2-.2-.52-.2-.71 0 -.2.19-.2.51 0 .7l4 4v-.71l-4 4c-.2.19-.2.51 0 .7 .19.19.51.19.7 0Z"/>
                        <path
                            d="M9.5 3H17s0-.001 0 0v2.5c0 .27.22.5.5.5 .27 0 .5-.23.5-.5V3c0-.56-.45-1-1-1H9.5c-.28 0-.5.22-.5.5 0 .27.22.5.5.5Z"/>
                        <path
                            d="M17 18.5V21s0 0 0 0H9.5c-.28 0-.5.22-.5.5 0 .27.22.5.5.5H17c.55 0 1-.45 1-1v-2.5c0-.28-.23-.5-.5-.5 -.28 0-.5.22-.5.5Z"/>
                        <path
                            d="M0 21.22c0 .46.32.87.78.97l7.99 1.77c.53.11 1.07-.23 1.19-.77 .01-.08.02-.15.02-.22l0-22.01c-.01-.56-.45-1.01-1.01-1 -.08 0-.15 0-.22.02L.74 1.75c-.46.1-.79.5-.79.97l-.01 18.44Zm.99-.01V2.76c-.01 0-.01 0-.01 0l8-1.78c-.01 0-.01 0-.01 0 -.001 0 0 0 0 0l-.01 21.99c0-.01 0-.01 0-.01 0-.001 0-.001 0-.001l-8-1.78c-.01-.01-.01-.01-.01-.01Z"/>
                        <path
                            d="M7 12c0 .55-.45 1-1 1 -.56 0-1-.45-1-1 0-.56.44-1 1-1 .55 0 1 .44 1 1Zm1 0c0-1.11-.9-2-2-2 -1.11 0-2 .89-2 2 0 1.1.89 2 2 2 1.1 0 2-.9 2-2Z"/>
                    </g>
                </svg>
            </Link>
            <Help show={help} onClose={()=>setHelp(false)}/>
        </nav>
    );
}
