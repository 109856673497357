import {FC, PropsWithChildren, ReactElement} from "react";
import {Welcome} from "../welcome/Welcome";
import {VSpace} from "../UI/spacer/Spacer";
import {Contact} from "../UI/contact/Contact";
import './Content.css';

type props = {
    list?: ReactElement
};

export const Content: FC<PropsWithChildren<props>> = ({children, list = null}): ReactElement => (
    <main className={"content"}>
        {list && <aside>{list}</aside>}
        <section className={list === null ? 'noside' : ''}>
            <Welcome/>
            <VSpace value={2}/>
            {children}
        </section>
        <Contact/>
    </main>
);
