import React from "react";

export const HelpQuota = () => (
    <>
        <h1>Anteile</h1>
        <p>Hier ist eine Auflistung alle Märkner und deren Anteile in tabellarischer Form.<br/>
        Auf der linken Seite können einzelne Marken aus- und/oder abgewählt werden.<br/>
           <div> <img src="/help/help-quota.png" alt="Anteile" height="60" /></div>
            <br/>
            Zum ab- und auswählen klicke einfach auf eine Mark. <br/>
            Zusätzlich kann nach Märknernamen gesucht werden. Die Tabelle aktualisiert sich während der Eingabe.<br/>

Die Tabelle kann nach verschiedenen Kriterien sortiert werden.
<ul>
    <li>A-Z: Nach Namen aufsteigend sortieren</li>
    <li>Z-A: Nach Namen absteigend sortieren</li>
    <li>1..n: Nach Anteilen (über alle Marken) aufsteigend sortieren </li>
    <li>n..1: Nach Anteilen (über alle Marken) absteigend sortieren </li>
</ul>

            <br/>
            Um eine Auswahl von Märknern als CSV herunterzuladen kannst Du diese in der Tabelle durch klicken an- und abwählen.
            Alternativ kannst Du <strong>Alles auswählen</strong> oder <strong>Nichts auswählen</strong>.

            Für den Download klicke auf die entsprechende Schaltfläche. Es muss jedoch mindestens ein Märkner aus der Liste ausgewählt werden, um den Download zu starten.


        </p>
    </>
);
