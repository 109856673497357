import {ReactElement, useState} from "react";
import {Menu} from "../../components/menu/Menu";
import {Content} from "../../components/content/Content";
import {ListOfPartners} from "../../components/sidebars/ListOfPartners";
import {EmptyContent} from "../../components/empty-content/EmptyContent";
import {useGetPartnerQuery} from "../../configure/api/businesspartner-api";
import {VirilData} from "../../models/quota.data";
import {$PartnerData, PartnerData} from "../../models/partner.data";
import {VSpace} from "../../components/UI/spacer/Spacer";
import {PartnerQuota} from "./partner-quota/PartnerQuota";
import {useNavigate, useParams} from "react-router-dom";
import {useGetQuotaValueQuery} from "../../configure/api/quota-api";
import {PartnerDelete} from "./partner-delete/PartnerDelete";
import {PartnerNew} from "./partner-new/PartnerNew";
import {useSelector} from "react-redux";
import {selectUser} from "../../configure/slice/auth-slice";
import {OutlineButton} from "../../components/UI/button/Button";
import {PartnerDetailsEdit} from "./partner-details/PartnerDetailsEdit";

export const Partner = (): ReactElement => {
    const $user = useSelector(selectUser);
    const params = useParams();
    const createNew = params.uid === 'new';
    const id = (params.uid === undefined || params.uid === 'new') ? -1 : parseInt(params.uid, 10);
    const $customer = useGetPartnerQuery(id, {skip: id === -1});
    const $values = useGetQuotaValueQuery(id, {skip: id === -1});
    const [mark, setMark] = useState<string>('');
    const $navigate = useNavigate();

    /**
     * draw empty content or data
     */
    const drawData = () => {

        if (createNew) {
            return <PartnerNew/>
        }

        if (id === -1) {
            return <EmptyContent/>;
        }

        const user: PartnerData | undefined = $customer?.data?.user;
        const list: VirilData[] | undefined = $customer?.data?.list;

        if (!user || !list) {
            return null;
        }

        return (
            <>
                {$user.canEdit &&
                <div className="row end">
                    <OutlineButton label={'Datenauskunft'}
                                   onClick={() => window.open(`/partner/${id}/info`, '_blank')}/>
                </div>
                }
                <VSpace/>


                <div className="customer--content">
                    <PartnerDetailsEdit data={user} section={'person'}/>
                    <VSpace/>
                    {
                        $PartnerData.isMaerkner(user) ?
                            <>
                                <PartnerQuota data={list} values={$values?.data || []} onShowMark={setMark} uid={id}/>
                                <VSpace/>
                            </> : null
                    }
                    <PartnerDetailsEdit data={user} section={'heritage'}/>
                    <VSpace/>
                    <PartnerDetailsEdit data={user} section={'contact'}/>
                    <VSpace/>
                    <PartnerDetailsEdit data={user} section={'note'}/>
                </div>
                {(list.length === 0 && $user.canEdit) &&
                <>
                    <PartnerDelete id={user.uid}
                                   onDeleted={() => $navigate('/partner')}/>
                    <VSpace/>
                </>}
            </>
        );
    };

    return (<>
            <Menu/>
            <Content list={<ListOfPartners selected={id} initialSearch={mark}/>}>
                {drawData()}
            </Content>
        </>
    );
}
