import {FC, useEffect, useRef, useState} from "react";
import {DatepickerMenu} from "./DatepickerMenu";
import {isToday, isTomorrow, isYesterday} from "date-fns";
import {$String} from "../../../utils/string";

type Props = {
    label: string,
    from: number,
    to: number,
    onChange: (start: number, end: number) => void
};

export const DateRange: FC<Props> = ({label, from, to, onChange}) => {
    const [open, setOpen] = useState<boolean>(false);
    const ref = useRef<any>();

    const expand = () => setOpen(true);
    const close = () => setOpen(false);

    const handleClickOutside = (event: any) => {
        if (ref && ref.current && !ref.current.contains(event.target)) {
            close();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        };
    }, []);

    const drawRange = () => {
        if (!open) {
            return null;
        }

        return (
            <div className="datepicker--menu">
                <div className={"row wrap"}>
                    <DatepickerMenu initialValue={from} onClick={(value) => {
                        onChange(value, to);
                    }
                    }/>
                    <DatepickerMenu initialValue={to} onClick={(value) => {
                        onChange(from, value);
                    }
                    }/>
                </div>
            </div>
        );
    }
    return (
        <div className="datepicker" ref={ref}>
            <div className="datepicker--input" tabIndex={0} onClick={expand}>
                <label>{label}</label>
                <div className="row">
                    {$String.smartDateRangeText(from, to)}
                    <div>ꜜ</div>
                </div>
            </div>
            {
                drawRange()
            }
        </div>
    );
};
