import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {$Crypt} from "../../utils/crypt";
import {BASEURL} from "./baseurl";
import {$PartnerData, PartnerData} from "../../models/partner.data";

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASEURL,
    }),
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        auth:  builder.query<{id: string}, void>({
            query: () => ({
                url: '/auth',
                method: 'GET',
            }),
        }),
        signIn: builder.mutation<void, { user: string, pass: string, token: string }>({
            query: ({user, pass, token}) => ({
                url: '/auth',
                method: 'POST',
                body: {user, pass: $Crypt.crypt(pass), _id: token},
            })
        }),
    }),
});

export const {
    useSignInMutation,
    useLazyAuthQuery
} = authApi;
