import {FC, Fragment} from "react";
import {$PartnerData, PartnerData} from "../../models/partner.data";
import {Headline} from "../UI/headline/Headline";
import {Input} from "../UI/input/Input";
import {$Quota} from "../../models/quota.data";
import {VSpace} from "../UI/spacer/Spacer";
import {SelectableCell} from "../UI/selectable-cell/SelectableCell";
import {$QuotaFilter, QuotaFilter} from "../../models/quotafilter.data";

type props = {
    list: PartnerData[],
    filter: QuotaFilter,
    onFilter: (filter: QuotaFilter) => void
};

const highImage = <svg width="32" height="32" fill="none" stroke="#bf0d55" strokeLinecap="round" strokeLinejoin="round"
                       strokeWidth="2" viewBox="0 0 24 24">
    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z"></path>
    <path d="m16 12-4-4-4 4"></path>
    <path d="M12 16V8"></path>
</svg>;

const lowImage = <svg width="32" height="32" fill="none" stroke="#bf0d55" strokeLinecap="round" strokeLinejoin="round"
                      strokeWidth="2" viewBox="0 0 24 24">
    <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z"></path>
    <path d="m8 12 4 4 4-4"></path>
    <path d="M12 8v8"></path>
</svg>;

const marks = Object.keys($Quota.MARK_MAPPING);

export const ListOfQuota: FC<props> = ({list, filter, onFilter}) => {
    const totals = $PartnerData.summaryMark(list);

    /**
     * filter handler
     * @param value
     */
    const updateSearch = (value: string) => {
        onFilter({...filter, search: value});
    }

    return (
        <>
            <Headline>Filter</Headline>
            <p>Nutze die Suche um die Liste einzuschränken. Klicke auf die Marken um zusätzlich die Daten zu
                filtern.</p>
            <Input initialValue={filter.search}
                   onChange={updateSearch}
                   type={"search"}
                   placeholder={'Suche nach Märknern...'}/>
            <VSpace/>
            {
                Object.keys($Quota.MARK_MAPPING).map((key: string) => (
                    <SelectableCell key={'quota-' + key}
                                    onClick={() => onFilter($QuotaFilter.toggleMark(filter, key))}
                                    active={$QuotaFilter.hasMark(filter, key)}>
                        <div className={"row vcenter"}>
                            <div>
                                <strong>{$Quota.convertMarkToSmartText(key)}</strong><br/>
                                {totals[key]} Anteile<br/>
                                <small>insgesamt {$Quota.MARK_QUOTALIMITS[key]}</small>
                            </div>
                            {totals[key] > $Quota.MARK_QUOTALIMITS[key] && highImage}
                            {totals[key] < $Quota.MARK_QUOTALIMITS[key] && lowImage}
                        </div>
                    </SelectableCell>
                ))
            }
        </>
    );
}
