import {useEffect, useMemo, useState} from "react";
import {$WoodSale, WoodsaleData} from "../../../models/woodsale.data";
import {VSpace} from "../../../components/UI/spacer/Spacer";
import {Card} from "../../../components/UI/card/Card";
import {$Fields, FieldsData} from "../../../models/fields.data";
import {useNavigate, useParams} from "react-router-dom";
import {Menu} from "../../../components/menu/Menu";
import {Headline} from "../../../components/UI/headline/Headline";
import {Content} from "../../../components/content/Content";
import {usePartnerListQuery} from "../../../configure/api/businesspartner-api";
import {createFromStringArray, DropdownItem} from "../../../components/UI/dropdown/Dropdown";
import {$PartnerData, PartnerCompany, PartnerData} from "../../../models/partner.data";
import {$Quota} from "../../../models/quota.data";
import {FormFields, validateFilled, validateNumber, ValidationData} from "../../../components/form-fields/FormFields";
import {
    useLazyWoodSalesByIdQuery, useLazyWoodSalesMetaQuery,
    useWoodSaleCreateMutation,
    useWoodSalesTypesAndUnitsQuery, useWoodSaleUpdateMutation
} from "../../../configure/api/wood-api";
import {ListOfSaleSummary} from "../../../components/sidebars/ListOfSaleSummary";
import {Store} from "react-notifications-component";

export const WoodDetails = () => {
    const params = useParams();
    const $partner = usePartnerListQuery();
    const $typesAndUnits = useWoodSalesTypesAndUnitsQuery();
    const [data, setData] = useState<WoodsaleData>($WoodSale.create());

    const createNew = params.uid === 'new';
    const id = (params.uid === undefined || params.uid === 'new') ? -1 : parseInt(params.uid, 10);
    const $navigate = useNavigate();
    const [$getWoodSale] = useLazyWoodSalesByIdQuery();
    const [$meta] = useLazyWoodSalesMetaQuery();
    const [$create] = useWoodSaleCreateMutation();
    const [$update] = useWoodSaleUpdateMutation();

    useEffect(() => {
        if (createNew) {
            $meta().unwrap().then((response) => {
                setData({...data, id: response.next});
            });
        } else {
            $getWoodSale(id).unwrap().then((response) => {
                setData(response);
            }).catch(() => {
                Store.addNotification({
                    message: "Daten konnten nicht ermittelt werden",
                    type: "danger",
                    container: "top-right",
                    dismiss: {
                        duration: 4000,
                    }
                });
                setTimeout(() => $navigate('/wood'), 4000);
            });
        }
    }, []);

    const goBack = () => setTimeout(() => $navigate("/wood"), 4000);

    const onUpdateOrCreate = () => {
        if (createNew) {
            $create(data).unwrap().then(() => {
                Store.addNotification({
                    message: "Neuer Beleg erfolgreich angelegt.",
                    type: "success",
                    container: "top-right",
                    dismiss: {
                        duration: 4000,
                    }
                });
                setTimeout(goBack, 4000);
            }).catch(() => {
                Store.addNotification({
                    message: "Beleg konnte nicht angelegt werden.",
                    type: "danger",
                    container: "top-right",
                    dismiss: {
                        duration: 4000,
                    }
                });
            });
        } else {
            $update({id: id, body: data}).unwrap().then(() => {
                Store.addNotification({
                    message: "Beleg aktualisiert.",
                    type: "success",
                    container: "top-right",
                    dismiss: {
                        duration: 4000,
                    }
                });
                setTimeout(goBack, 4000);
            }).catch(() => {
                Store.addNotification({
                    message: "Beleg konnte nicht aktualisiert werden.",
                    type: "danger",
                    container: "top-right",
                    dismiss: {
                        duration: 4000,
                    }
                });
            });
        }
    };

    const getPartnerList = (): DropdownItem[] => {
        if (!$partner.data) {
            return [];
        }
        return $partner.data.filter(customer => !$PartnerData.isMaerkner(customer)).map((item: PartnerData) => ({
            label: (item as PartnerCompany).name, value: item.uid
        }))
    };

    const getMarkList = (): DropdownItem[] => (
        Object.keys($Quota.MARK_MAPPING).map(key => ({label: $Quota.MARK_MAPPING[key], value: key}))
    );

    const getTypes = (): DropdownItem[] => (
        createFromStringArray($typesAndUnits?.data?.types ?? [])
    );

    const getUnits = (): DropdownItem[] => (
        createFromStringArray($typesAndUnits?.data?.units ?? [])
    );

    const FIELDS: FieldsData[] = [
        $Fields.create('Belegnummer', 'id', 50, false, 'label'),
        $Fields.create('Datum', 'date', 50, true, 'date'),
        $Fields.create('Geschäftspartner', 'uid', 50, false, 'select', getPartnerList()),
        $Fields.create('Mark', 'mark', 50, true, 'select', getMarkList()),
        $Fields.create('Skonto', 'discount', 50, false, 'text'),
        $Fields.create('Provision', 'commission', 50, true, 'text'),
        $Fields.create('', '', 100, true, 'separator'),
        $Fields.create('Holzart', 'type', 33, false, 'select', getTypes()),
        $Fields.create('Einheit', 'unit', 33, false, 'select', getUnits()),
        $Fields.create('Menge', 'quantity', 33),
        $Fields.create('Preis (brutto)', 'price', 50, false, 'text'),
        $Fields.create('Bezahlt', 'paid', 50, true, 'text'),
        $Fields.create('', '', 100, true, 'separator'),
        $Fields.create('Anmerkung', 'note', 100, true, 'multi'),
        $Fields.create('Direktverkauf', 'direct', 50, false, 'yesno'),
        $Fields.create('MwSt. ausgewiesen', 'tax_shown', 50, true, 'yesno'),
        $Fields.create('MwSt. bezahlt', 'tax_paid', 50, false, 'yesno'),
        $Fields.create('Abgeschlossen', 'done', 50, true, 'yesno'),
    ];

    const validator: ValidationData = useMemo(() =>
        ({
            'id': (value: string) => validateFilled(value + '', 'Belegnummer erforderlich'),
            'uid': (value: any) => (value ?? 0) === 0 ? 'Geschäftspartner erforderlich' : undefined,
            'mark': (value: any) => validateFilled(value, 'Mark erforderlich.'),
            'discount': (value: any) => validateNumber(value, 0, 100),
            'quantity': (value: any) => validateNumber(value, 0),
            'price': (value: any) => validateNumber(value, 0),
            'paid': (value: any) => validateNumber(value, 0),
            'type': (value: any) => validateFilled(value, 'Holzart erforderlich.'),
            'unit': (value: any) => validateFilled(value, 'Einheit erforderlich.'),
        }), []);

    const drawTitle = () => {
        if (createNew) {
            return <p>Hier kannst Du einen neuen Beleg anlegen. Unabhängig ob der Vorgang bereits komplett abgeschlossen
                ist oder nicht.</p>;
        }
        return <p>Hier kannst Du den ausgewählten Beleg bearbeiten.</p>
    };

    return (
        <>
            <Menu/>
            <Content list={<ListOfSaleSummary data={data}/>}>
                <Headline>Holzverkäufe</Headline>

                {drawTitle()}
                <VSpace/>
                <Card title={createNew ? 'Neuen Beleg anlegen' : 'Beleg bearbeiten'}>
                    <FormFields fields={FIELDS}
                                validator={validator}
                                data={data}
                                onUpdate={(key: string, value: any) => {
                                    setData({
                                        ...data,
                                        [key]: value
                                    });
                                }}
                                onSubmit={(flag) => {
                                    if (flag) {
                                        onUpdateOrCreate();
                                    } else {
                                        $navigate("/wood");
                                    }
                                }}/>
                </Card>
            </Content>
        </>
    );
};
