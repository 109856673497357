import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {$PartnerData, PartnerData, PARTNERTYPE} from "../../models/partner.data";
import {$Quota, QuotaData} from "../../models/quota.data";
import {$Http} from "../../utils/http";
import {$Object} from "../../utils/object";
import {BASEURL} from "./baseurl";

export const businesspartnerApi = createApi({
    reducerPath: 'businesspartnerApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASEURL,
        prepareHeaders: $Http.prepareHeaders,
    }),
    tagTypes: ['list', 'partner'],
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        partnerList: builder.query<PartnerData[], void>({
            query: () => ({
                url: '/partner',
                method: 'GET',
            }),
            providesTags: ['list'],
            transformResponse: (response: PartnerData[]) => response.map($PartnerData.create)
        }),
        getPartner: builder.query<QuotaData, number>({
            query: (uid) => ({
                url: `/partner/${uid}`,
                method: 'GET',
            }),
            providesTags: ['partner'],
            transformResponse: (response: QuotaData) => $Quota.create(response)
        }),
        collectPartnerInfo: builder.query<any, number>({
            query: (uid) => ({
                url: `/partner/info/${uid}`,
                method: 'GET',
            })
        }),
        getPartnerForViril: builder.query<PartnerData[], string>({
            query: (mark) => ({
                url: `/partner/viril/${mark}`,
                method: 'GET',
            }),
            providesTags: ['partner'],
            transformResponse: (response: PartnerData[]) => response.map($PartnerData.create)
        }),
        createPartner: builder.mutation<{ id: number }, { type: string, data: PartnerData }>({
            query: ({type, data}) => ({
                url: `/partner/${type}`,
                method: 'POST',
                body: $Object.onlyAttributes(data, type === PARTNERTYPE.MAERKNER ?
                    'letter title salutation firstname lastname street zip city taxid' :
                    'name street zip city taxid'
                )
            }),
            invalidatesTags: ["list"]
        }),
        updatePartner: builder.mutation<void, { attributes: string, data: PartnerData }>({
            query: ({attributes, data}) => ({
                url: `/partner/${data.uid}`,
                method: 'PUT',
                body: $Object.onlyAttributes(data, attributes)
            }),
            invalidatesTags: ['list', 'partner']
        }),

        deletePartner: builder.mutation<void, number>({
            query: (id) => ({
                url: `/partner/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ["list"]
        }),
        download: builder.mutation<any, number[]>({
            query: (list) => ({
                url: '/partner/export',
                method: 'POST',
                body: {list},
                responseHandler: 'text'
            }),
        }),
    }),
});

export const {
    usePartnerListQuery,
    useGetPartnerQuery,
    useGetPartnerForVirilQuery,
    useUpdatePartnerMutation,
    useDeletePartnerMutation,
    useCreatePartnerMutation,
    useDownloadMutation,
    useCollectPartnerInfoQuery
} = businesspartnerApi;
