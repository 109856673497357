export const $Crypt = {
    pad: (num: number, length: number) => {
        let str = '' + num;
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    },
    crypt: (value: string): string => {
        let enc = '';
        for (let i = 0; i < value.length; i++) {
            enc += $Crypt.pad(value.charCodeAt(i) + 42, 3);
        }
        return enc;
    }
};
