import React, {FC} from "react";
import {useAddQuotaMutation, useGetAvailableQuery} from "../../../configure/api/quota-api";
import {VSpace} from "../../../components/UI/spacer/Spacer";
import {Card} from "../../../components/UI/card/Card";
import {Store} from 'react-notifications-component';
import {QuotaUserInput} from "./QuotaUserInput";
import {useDispatch} from "react-redux";
import {businesspartnerApi} from "../../../configure/api/businesspartner-api";

type props = {
    uid: number
};

export const QuotaAdd: FC<props> = ({uid}) => {
    const $available = useGetAvailableQuery();
    const [$add] = useAddQuotaMutation();
    const dispatch = useDispatch();

    /**
     * add new quota
     * @param mid
     * @param userInput
     */
    const onAddQuota = (mid: string, userInput: string) => {
        const value = parseFloat(userInput) * 1000;
        $add({uid, data: {mid: mid, quota: value}}).unwrap().then(() => {
            Store.addNotification({
                message: "Anteil erfolgreich zugewiesen",
                type: "success",
                container: "top-right",
                dismiss: {
                    duration: 4000,
                }
            });
            // auto fetch customer data
            dispatch(businesspartnerApi.util.invalidateTags(['partner']));
        }).catch(response => {
            Store.addNotification({
                message: response.data.error,
                type: "danger",
                container: "top-right",
                dismiss: {
                    duration: 4000,
                }
            });
        });
    }

    const list = $available?.data ?? [];

    if (list.length === 0) {
        return (
            <Card title={'Anteil hinzufügen'}>
                <p>Es sind keine freien Anteile verfügbar.</p>
            </Card>
        );
    }

    return (
        <Card title={'Anteil hinzufügen'}>
            <p>Wähle den Virilanteil aus der Liste aus. Die verfügbaren Anteile werden dann angezeigt. </p>
            <VSpace/>

            <QuotaUserInput
                label={'Hinzufügen'}
                list={list}
                onEnd={onAddQuota}/>

        </Card>
    );
};
