import {$Quota} from "./quota.data";

export interface QuotaFilter {
    search: string,
    mark: { [key: string]: boolean }
}

export const $QuotaFilter = {
    create: (obj: any = {}): QuotaFilter => {
        const item = {
            search: obj.search ?? '',
            mark: {}
        };
        for (let key in $Quota.MARK_MAPPING) {
            // @ts-ignore
            item.mark[key] = obj?.mark?.[key]===undefined ? true : obj?.mark?.[key];
        }
        return item;
    },
    hasMark: (obj: QuotaFilter, mark: string): boolean => obj.mark[mark],
    toggleMark: (obj: QuotaFilter, mark: string): QuotaFilter => {
        const tmp = $QuotaFilter.create(obj);
        tmp.mark[mark] = !tmp.mark[mark];
        return tmp;
    },
    toString: (obj: QuotaFilter): string => {
        let buf = '';
        if (obj.search.trim().length > 0) {
            buf += `Suche nach "${obj.search}". `;
        }
        const list = Object.keys(obj.mark).filter((key => obj.mark[key]));
        if (list.length > 0) {
            buf += `Gefiltert nach ${list.map(key => $Quota.convertMarkToSmartText(key)).join(', ')}`;
        }
        return buf.length > 0 ? (buf + '.') : '';
    }
};
