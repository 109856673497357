import {$Quota} from "./quota.data";
import {endOfDay, isAfter, isBefore, startOfDay} from "date-fns";
import {$Object} from "../utils/object";

export interface WoodsaleData {
    id: number,
    uid: number,
    date: number,
    discount: number,
    commission: number,
    mark: string,
    type: string,
    quantity: number,
    unit: string,
    price: number,
    paid: number,
    done: boolean,
    note: string,
    direct: boolean,
    tax_paid: boolean,
    tax_shown: boolean,
}

export interface FilterWood {
    from: number,
    to: number,
    done: boolean
}

export interface FilterPreset extends FilterWood {
    label: string
}

export const $WoodSale = {
    createFilter: (): FilterWood => ({
        from: new Date().getTime(),
        to: new Date().getTime(),
        done: true
    }),
    preset: (label: string, from: number, to: number, done: boolean): FilterPreset => ({label, from, to, done}),
    create: (obj: any = {}): WoodsaleData => ({
        id: obj.id ?? 0,
        uid: obj.uid ?? 0,
        date: obj.date === undefined ? new Date().getTime() : obj.date,
        discount: obj.discount ?? 0,
        commission: obj.commission ?? 0,
        mark: obj.mark ?? '',
        type: obj.type ?? 'Fichte',
        quantity: obj.quantity ?? 0,
        unit: obj.unit ?? 'RM',
        price: obj.price ?? 0,
        paid: obj.paid ?? 0,
        done: obj.done ?? true,
        note: obj.note ?? '',
        direct: obj.direct ?? false,
        tax_paid: obj.tax_paid ?? false,
        tax_shown: obj.tax_shown ?? false,
    }),
    filter: (list: WoodsaleData[], filter: FilterWood): WoodsaleData[] => {

        const from = startOfDay(filter.from);
        const to = endOfDay(filter.to);
        return list.filter((item: WoodsaleData) => {

            const date = $Object.dateWithoutTime(item.date);
            if (isAfter(item.date, from) && isBefore(item.date, to)) {
                return item.done === filter.done;
            }
            return false;
        }).sort((a,b) => b.id - a.id);
    },
    statistics: (list: WoodsaleData[]) => {
        return Object.keys($Quota.MARK_MAPPING).reduce((acc, cur) => {
            const data = list.filter((item: WoodsaleData) => item.mark === cur);
            // TODO quantity depends on unit and type
            const quantity = data.reduce((qa: any, c) => {
                // unit
                if (!qa[c.unit]) {
                    qa[c.unit] = 0;
                }
                qa[c.unit] += c.quantity;
                return qa;
            }, {});

            return {...acc, [cur]: {count: data.length, quantity}};
        }, {});
    },
}
