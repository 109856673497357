import {ReactElement} from "react";
import {format} from "date-fns";
import {de} from "date-fns/locale";
import {useSelector} from "react-redux";
import {selectUser} from "../../configure/slice/auth-slice";
import logo from '../../assets/logo.jpg';
import {Link} from "react-router-dom";
import './Welcome.css';

export const Welcome = (): ReactElement => {

    const $user = useSelector(selectUser);

    /**
     * say hello to the user
     */
    const sayHello = (): string => {
        const hour = new Date().getHours();
        if (hour >= 5 && hour < 11) {
            return 'Guten Morgen';
        }
        if (hour >= 11 && hour < 18) {
            return 'Guten Tag';
        }
        return 'Guten Abend';
    }

    return (
        <div className={'welcome'}>
            <div className={'welcome--first'}>
                <div className="row start vcenter">
                    <img src={logo} alt="logo"/>
                    <div>Markenverband<br/><strong>Winterberg</strong></div>
                </div>

            </div>
            <div className="welcome--last">
                <div className={'welcome--name'}>{sayHello()}, <Link to={'/profile'}><strong>{$user?.name}</strong></Link></div>
                <div className="welcome--date">{format(new Date(), 'dd.MMMyyyy', {locale: de})}</div>
            </div>
        </div>
    );
}
