import {PartnerData} from "./partner.data";

export interface UsufructData {
    mid: string,
    usufruct: number
}

export interface UsufructResponse {
    [key: number]: UsufructData[]
}

export const $Usufruct = {
    create: (obj: any = {}): UsufructData => ({
        mid: obj.mid ?? '',
        usufruct: obj.usufruct ?? 0
    }),
    usufructFor: (list: UsufructData[], customers: PartnerData[], mid: string): PartnerData | null => {
        const uf = list.find((item) => item.mid === mid) || null;
        if (uf) {
            return customers.find((item) => item.uid === uf.usufruct) || null;
        }
        return null;
    },
    has: (list: UsufructData[], mid:string): boolean => !!(list.find((item) => item.mid === mid))
};
