import {Card} from "../../../components/UI/card/Card";
import {useMemo, useState} from "react";
import {$PartnerData, PartnerData, PARTNERTYPE} from "../../../models/partner.data";
import {VSpace} from "../../../components/UI/spacer/Spacer";
import {useCreatePartnerMutation} from "../../../configure/api/businesspartner-api";
import {useNavigate} from "react-router-dom";
import {Store} from "react-notifications-component";
import {Select} from "../../../components/UI/select/Select";
import {FormFields, validateFilled, ValidationData} from "../../../components/form-fields/FormFields";
import {FieldsData} from "../../../models/fields.data";

type PartnerNewLayout = {
    label: string,
    fields: FieldsData[],
    validate: ValidationData
};

export const PartnerNew = () => {
    const [customer, setCustomer] = useState<PartnerData>($PartnerData.create());
    const $navigate = useNavigate();
    const [type, setType] = useState<string>(PARTNERTYPE.MAERKNER);
    const [$save] = useCreatePartnerMutation();

    const list = useMemo((): { [key: string]: PartnerNewLayout } =>
        ({
            [PARTNERTYPE.MAERKNER]: {
                label: 'Märkner',
                fields: $PartnerData.fieldsPerson,
                validate: {
                    'lastname': (value: string) => validateFilled(value, 'Nachname ist erforderlich')
                }
            },
            [PARTNERTYPE.COMPANY]: {
                label: 'Firma',
                fields: $PartnerData.fieldsCompany,
                validate: {
                    'name': (value: string) => validateFilled(value, 'Name ist erforderlich'),
                    'taxid': (value: string) => validateFilled(value, 'Umsatzsteuer ID ist erforderlich')
                }
            }
        }), []);

    const drawTypeSelection = () => {
        return (
            <div className={"row start"}>
                {
                    Object.keys(list).map(key => (
                        <Select key={key}
                                onClick={() => {
                                    setType(key);
                                    setCustomer($PartnerData.create({type: key}));
                                }} active={type === key}>{list[key].label}</Select>
                    ))
                }
            </div>
        );
    };

    return (
        <Card title={'Geschäftspartner anlegen'}>
            <p>Hier können die persönlichen Daten für einen Geschäftspartner angegeben werden. Danach wird dieser
                automatisch
                angezeigt und kann weiter bearbeitet werden.</p>
            <VSpace/>

            <p>Wähle zunächst, welche Art Geschäftspartner Du anlegen möchtest.</p>
            <VSpace/>
            {drawTypeSelection()}
            <VSpace/>
            <FormFields fields={list[type].fields}
                        validator={list[type].validate}
                        data={customer}
                        onUpdate={(key: string, value: string) => setCustomer({
                            ...customer,
                            [key]: value
                        })}
                        onSubmit={(flag) => {
                            if (flag) {
                                $save({type, data: customer}).unwrap().then((payload) => {
                                    $navigate(`/partner/${payload.id}`);
                                }).catch(() => {
                                    Store.addNotification({
                                        message: 'Geschäftspartner konnte nicht angelegt werden.',
                                        type: "danger",
                                        container: "top-right",
                                        dismiss: {
                                            duration: 4000,
                                        }
                                    });
                                });
                            } else {
                                $navigate('/partner');
                            }
                        }}/>
        </Card>
    );
}
