import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { VirilAvailable, VirilValue} from "../../models/quota.data";
import {$Http} from "../../utils/http";
import {BASEURL} from "./baseurl";

export const quotaApi = createApi({
    reducerPath: 'quotaApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASEURL,
        prepareHeaders: $Http.prepareHeaders,
    }),
    keepUnusedDataFor: 0,
    tagTypes: ['list', 'quota'],
    endpoints: (builder) => ({
        getQuotaValue: builder.query<VirilValue[], number>({
            query: (uid) => ({
                url: `/quota/value/${uid}`,
                method: 'GET',
            }),
            providesTags: ['quota'],
        }),
        getAvailable: builder.query<VirilAvailable[], void>({
            query: () => ({
                url: `/quota/available`,
                method: 'GET',
            }),
            providesTags: ['quota'],
        }),
        addQuota: builder.mutation<any, { uid: number, data: VirilAvailable }>({
            query: ({uid, data}) => ({
                url: `/quota/${uid}`,
                method: 'POST',
                body: {
                    amount: data.quota,
                    mid: data.mid
                }
            }),
            invalidatesTags: ['list', 'quota']
        }),
        removeQuota: builder.mutation<any, { uid: number, data: VirilAvailable }>({
            query: ({uid, data}) => ({
                url: `/quota/${uid}/${data.mid}/${data.quota}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['list', 'quota']
        }),
    }),
});

export const {
    useGetQuotaValueQuery,
    useGetAvailableQuery,
    useAddQuotaMutation,
    useRemoveQuotaMutation
} = quotaApi;
