import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {$HistoryData, HistoryData} from "../../models/history.data";
import {$Http} from "../../utils/http";
import {BASEURL} from "./baseurl";

export const historyApi = createApi({
    reducerPath: 'historyApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASEURL,
        prepareHeaders: $Http.prepareHeaders,
    }),
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        listHistory: builder.query<HistoryData[], {filter: number, limit: number}>({
            query: ({filter, limit}) => ({
                url: `/history/list/${filter}/${limit}`,
                method: 'GET',
            }),
            transformResponse: (response: HistoryData[]) => response.map($HistoryData.create)
        }),
        listHistoryNames: builder.query<string[], void>({
            query: () => ({
                url: '/history/names',
                method: 'GET',
            })
        }),
    }),
});

export const {
    useListHistoryQuery,
    useListHistoryNamesQuery,
} = historyApi;
