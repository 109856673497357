import React from "react";

export const HelpHistory = () => (
    <>
        <h1>Protokolldaten</h1>
        <p>Alle durchgeführten Aktionen werden protokolliert. Hier siehst Du die letzten Einträge mit
        <ul>
            <li>Datum</li>
            <li>Benutzer</li>
            <li>Aktionsbeschreibung</li>
        </ul>
            <br/>
            Die Anzahl der Einträge kannst Du über
            <div><img src="/help/help-history.png" alt="Protokoll" height="25"/></div>
            auswählen. Die aktuelle dargestellte Anzahl ist farblich hinterlegt.
        </p>
    </>
);
