import {FC} from "react";
import './Dot.css';

type props = {
    label: string,
    active?: boolean,
    outline?: boolean
}

export const Dot: FC<props> = ({label, outline = false, active = false}) => (
    <div className={`dot ${active ? 'active' : ''} ${outline ? 'outline' : ''}`}>{label}</div>
);
