import {RootState} from "../configure/configure";

export const $Http = {
    prepareHeaders: (headers: Headers, {getState}: any) => {
        const {token} = (getState() as RootState).user.data;

        if (token) {
            headers.set('authorization', token);
        }

        return headers
    },
    download: (res: any, filename: string) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
    }
}
