import './EmptyContent.css';
import {VSpace} from "../UI/spacer/Spacer";
import cat from '../../assets/empty/cat.gif';

export const EmptyContent = () => {

    return (
        <div className={'empty--content'}>
            <img src={cat} alt="" style={{width:'128px'}}/>

            <VSpace/>
            <div className="title">
                Aktuell gibt es hier keine Inhalte<br/> oder die Daten konnte nicht geladen werden.
            </div>
        </div>
    );
}
