import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {$Http} from "../../utils/http";
import {BASEURL} from "./baseurl";

export const adminApi = createApi({
    reducerPath: 'adminApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASEURL,
        prepareHeaders: $Http.prepareHeaders,
    }),
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        sendContact: builder.mutation<void, {message:string,route:string}>({
            query: ({message,route}) => ({
                url: '/admin/contact',
                method: 'POST',
                body: {
                    message,
                    route
                }
            }),
        }),
        changePassword: builder.mutation<void, string>({
            query: (password) => ({
                url: '/admin/password',
                method: 'PUT',
                body: {
                    password,
                }
            }),
        }),
    }),
});

export const {
    useSendContactMutation,
    useChangePasswordMutation
} = adminApi;

