export interface UserData {
    name: string,
    canEdit: boolean,
    token: string,
    error: number
}

export const $UserData = {
    create: (obj: any = {}): UserData => ({
        name: obj.name ?? '',
        canEdit: obj.permission === 'write',
        token: obj.token ?? '',
        error: obj.error ?? 0
    })
};
