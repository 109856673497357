import {FC, PropsWithChildren, ReactElement} from "react";
import CountUp from "react-countup";
import './Tile.css';

type props = {
    label?: string,
    value: number,
    small?: boolean,
    light?: boolean
};

export const Tile: FC<PropsWithChildren<props>> = ({
                                                       children,
                                                       small = false,
                                                       light = false,
                                                       label,
                                                       value
                                                   }): ReactElement => (
    <div className={`tile ${light ? 'light' : ''} ${small ? 'small' : ''}`} key={'tile-' + label}>
        {label && <div className="key">{label}</div>}
        <div className="value">
            <CountUp duration={0.5} end={value}/>
        </div>
        {children}
    </div>
);

export const TileNoValue: FC<PropsWithChildren> = ({children}) => (
    <div className={"tile fullheight"}>{children}</div>
)
