import {Menu} from "../../components/menu/Menu";
import {Content} from "../../components/content/Content";
import {EmptyContent} from "../../components/empty-content/EmptyContent";
import {useWoodSaleDownloadMutation, useWoodSalesListQuery} from "../../configure/api/wood-api";
import {SelectableCell} from "../../components/UI/selectable-cell/SelectableCell";
import {format} from "date-fns";
import {de} from "date-fns/locale";
import {Headline} from "../../components/UI/headline/Headline";
import {Fragment, useEffect, useState} from "react";
import {HSpace, VSpace} from "../../components/UI/spacer/Spacer";
import {ListOfSales} from "../../components/sidebars/ListOfWood";
import {$WoodSale, FilterWood, WoodsaleData} from "../../models/woodsale.data";
import {$String} from "../../utils/string";
import {$Quota} from "../../models/quota.data";
import {Dot} from "../../components/UI/dot/Dot";
import {Tag} from "../../components/UI/tag/Tag";
import {usePartnerListQuery} from "../../configure/api/businesspartner-api";
import {$PartnerData} from "../../models/partner.data";
import {useSelector} from "react-redux";
import {selectUser} from "../../configure/slice/auth-slice";
import {Store} from "react-notifications-component";
import {Link} from "../../components/UI/link/Link";
import {$Http} from "../../utils/http";

export const Wood = () => {

    const $user = useSelector(selectUser);
    const $partner = usePartnerListQuery();
    const $sales = useWoodSalesListQuery();
    const [filter, setFilter] = useState<FilterWood>($WoodSale.createFilter());
    const [list, setList] = useState<WoodsaleData[]>([]);
    const [stat, setStat] = useState({});
    const [$download] = useWoodSaleDownloadMutation();

    const updateWithFilter = () => {
        const l = $WoodSale.filter(
            $sales?.data ?? [],
            filter
        );
        setList(l);

        // make stats
        setStat($WoodSale.statistics(l));
    };

    useEffect(() => {
        if ($sales.data) {
            updateWithFilter();
        }
    }, [$sales.data]);

    useEffect(updateWithFilter, [filter]);

    /**
     * draw from which mark it is sold
     * @param data
     */
    const drawMark = (data: any) => {
        const sections = Object.keys($Quota.MARK_MAPPING);
        return (
            <div className={"row end"}>
                {
                    sections.map((item: string, index: number) => <Dot key={item + index} label={item}
                                                                       outline={item !== data.mark}
                                                                       active={item === data.mark}/>)
                }
            </div>);
    };

    /**
     * draw one sale
     * @param item
     * @param index
     */
    const drawSale = (item: any, index: number) => {
        // @ts-ignore
        const name = $PartnerData.getByUid($partner?.data, item.uid)?.["name"] ?? '';
        return (
            <SelectableCell dark
                            onClick={() => null}
                            key={item.date + name + index}>
                <div className="row">
                    <div><span
                        className={"color-primary"}>#{item.id}</span> am {format(new Date(item.date), 'd. MMM yyyy', {locale: de})}
                    </div>
                    <div>{drawMark(item)}</div>
                </div>
                <VSpace/>
                <div className="row">
                    <div className="width-40"><strong className="font-bigger">{name}</strong></div>
                    <div className="width-20">{item.quantity} <strong>{item.unit}</strong> {item.type}</div>
                    <div className="width-20 align-right"><strong>{item.price}</strong> EUR</div>
                </div>
                <VSpace/>
                <div className="row start">
                    {!!item.direct && <Tag type="grey">Direktverkauf</Tag>}
                    {item.discount > 0 && <Tag type="black">{item.discount}% Skonto</Tag>}
                    {item.commision > 0 && <Tag type="black">{item.discount}% Vermittlung</Tag>}
                    {!!item.tax_paid && <Tag type="outline">MwSt. bezahlt</Tag>}
                    {!!item.tax_shown && <Tag type="outline">MwSt. ausgewiesen</Tag>}
                </div>
                {
                    $user.canEdit && <div className="row end"><a href={`/wood/${item.id}`}>Bearbeiten</a></div>
                }

            </SelectableCell>
        );
    };

    const onPartialExport = () => {
        const ids = list.map((item:WoodsaleData) => item.id);
        $download(ids).unwrap()
            .then((res) => {
                const filename = $String.smartDateRangeText(filter.from, filter.to).replace(/ /g, '');
                $Http.download(res, `wood-${filename}-export.csv`);
            })
            .catch(() => {
                Store.addNotification({
                    message: "Teilexport fehlgeschlagen.",
                    type: "danger",
                    container: "top-right",
                    dismiss: {
                        duration: 4000,
                    }
                });
            });
    };

    const onExport = () => {
        $download([]).unwrap()
            .then((res) => {
                $Http.download(res, 'wood-all-export.csv');
            })
            .catch(() => {
                Store.addNotification({
                    message: "Gesamtexport fehlgeschlagen.",
                    type: "danger",
                    container: "top-right",
                    dismiss: {
                        duration: 4000,
                    }
                });
            });
    };

    /**
     * draw statistic splitted by mark and unit
     */
    const drawStatistics = () => {
        const total: { [key: string]: number } = {};
        return (
            <div className="row start">
                {
                    Object.keys(stat).map((mark: string, index: number) => {
                        // @ts-ignore
                        const item = stat[mark];

                        return (
                            <SelectableCell dark onClick={() => null} key={item + index}>
                                <strong className={"color-primary"}>{$Quota.convertMarkToSmartText(mark)}</strong><br/>
                                <div className={"font-smaller"}>
                                    {item.count === 0 ? '---' :
                                        <>
                                            <strong>{item.count}</strong> Belege<br/>
                                            {
                                                Object.keys(item.quantity).map(qu => {
                                                    if (total[qu] === undefined) {
                                                        total[qu] = 0;
                                                    }
                                                    total[qu] += item.quantity[qu];
                                                    return (<Fragment key={qu}>
                                                        <strong>{item.quantity[qu].toFixed(1)}</strong> {qu}
                                                    </Fragment>)
                                                })
                                            }
                                        </>
                                    }
                                </div>
                            </SelectableCell>
                        )
                    })
                }
                <SelectableCell dark onClick={() => null}>
                    <strong className={"color-primary"}>Insgesamt</strong><br/>
                    {
                        Object.keys(total).map(key => (<div className="font-smaller"><strong>{total[key].toFixed(1)}</strong> {key}</div>))
                    }
                </SelectableCell>
            </div>
        );
    };

    /**
     * draw the main content
     */
    const drawContent = () => {
        if (list.length === 0) {
            return <EmptyContent/>;
        }

        return (
            <>
                {drawStatistics()}
                {list.map(drawSale)}
            </>
        );
    };

    return <>
        <Menu/>
        <Content list={<ListOfSales filter={filter} onFilter={setFilter}/>}>
            <Headline>Holzverkäufe</Headline>

            <p>
                <strong>{list.length}</strong> {$String.plural(list.length, 'Beleg', 'Belege')} gefunden
                für <strong>{$String.smartDateRangeText(filter.from, filter.to)}</strong> die <strong>{filter.done ? 'erledigt' : 'noch offen'} sind</strong>.
            </p>

            <div className="row end">
                <Link onClick={onPartialExport}>Angezeigte Belege exportieren</Link>
                <HSpace/>
                <Link onClick={onExport}>Alles exportieren</Link>
            </div>
            <VSpace/>
            {drawContent()}
        </Content>
    </>
};
