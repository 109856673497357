import React, {FC} from "react";
import {useRemoveQuotaMutation} from "../../../configure/api/quota-api";
import {VSpace} from "../../../components/UI/spacer/Spacer";
import {Card} from "../../../components/UI/card/Card";
import {Store} from 'react-notifications-component';
import {QuotaUserInput} from "./QuotaUserInput";
import {businesspartnerApi, useGetPartnerQuery} from "../../../configure/api/businesspartner-api";
import {VirilAvailable, VirilData} from "../../../models/quota.data";
import {useDispatch} from "react-redux";

type props = {
    uid: number
};

export const QuotaDelete: FC<props> = ({uid}) => {

    const $user = useGetPartnerQuery(uid, {skip: uid === -1});
    const [$remove] = useRemoveQuotaMutation();
    const dispatch = useDispatch();
    const list: VirilData[] | undefined = $user?.data?.list ?? [];

    /**
     * remove quota
     * @param mid
     * @param userInput
     */
    const onRemoveQuota = (mid: string, userInput: string) => {
        const value = parseFloat(userInput) * 1000;
        $remove({uid, data: {mid: mid, quota: value}}).unwrap().then(() => {
            Store.addNotification({
                message: "Anteil erfolgreich entfernt",
                type: "success",
                container: "top-right",
                dismiss: {
                    duration: 4000,
                }
            });
            // auto fetch customer data
            dispatch(businesspartnerApi.util.invalidateTags(['partner']));
        }).catch(response => {
            Store.addNotification({
                message: response.data.error,
                type: "danger",
                container: "top-right",
                dismiss: {
                    duration: 4000,
                }
            });
        });
    }

    if (list.length === 0) {
        return (
            <Card title={'Anteil entfernen'}>
                <p>Es sind keine Anteile zugewiesen. Wähle auf der linken Seite Anteile hinzufügen.</p>
            </Card>
        );
    }

    return (
            <Card title={'Anteil entfernen'}>
                <p>Wähle den Virilanteil aus der Liste aus. Dieser kann dann komplett oder teilweise gelöscht
                    werden.</p>
                <VSpace/>

                <QuotaUserInput label={'Entfernen'}
                                list={list as VirilAvailable[]}
                                onEnd={onRemoveQuota}/>

            </Card>
    );
};
