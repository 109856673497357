import {Menu} from "../../components/menu/Menu";
import {Content} from "../../components/content/Content";
import {useDashboardQuery} from "../../configure/api/dashboard-api";
import {$Quota} from "../../models/quota.data";
import {Tile, TileNoValue} from "../../components/UI/tile/Tile";
import {HSpace, VSpace} from "../../components/UI/spacer/Spacer";
import {CSSProperties, Fragment} from "react";
import 'charts.css';

export const Dashboard = () => {

    const $dashboard = useDashboardQuery();

    const drawMarks = () => {
        const marks = $dashboard?.data?.mark;
        if (!marks) {
            return null;
        }
        const keys = Object.keys(marks).sort();

        return (
            <>
                {
                    keys.map((key: string, index: number) => (
                        <Fragment key={key}>
                            <div className="width-20">
                                <Tile label={$Quota.convertMarkToSmartText(key)} value={marks[key] ?? 0}/>
                            </div>
                            {index < keys.length - 1 && <HSpace/>}
                        </Fragment>
                    ))
                }
            </>
        );
    };

    const years = ($dashboard?.data?.wood?.years ?? {});
    const yearKeys = Object.keys(years).sort().reverse().splice(0,4);
    const maxValue = yearKeys.reduce((acc, cur) => acc + years[cur],0);

    return (
        <>
            <Menu/>
            <Content>
                <div className="row start">
                    <div className="width-20">
                        <Tile label={'Märkner'} value={$dashboard?.data?.partner?.m ?? 0}/>
                    </div>
                    <HSpace/>
                    {drawMarks()}
                </div>
                <VSpace/>
                <div className="row" style={{height: '200px'}}>
                    <div className="width-20">
                        <Tile label={'Firmen'} value={$dashboard?.data?.partner?.c ?? 0}/>

                        {/*<Tile small value={$dashboard?.data?.invoice?.week}>Rechnungen diese Woche</Tile>*/}
                        {/*<VSpace value={0.5}/>*/}
                        {/*<Tile small value={$dashboard?.data?.invoice?.overdue}>in Zahlungsverzug</Tile>*/}
                    </div>
                    <HSpace/>
                    <div className="width-80">
                        <TileNoValue>
                            <strong>Holzverkäufe nach Holzmenge</strong><br/>
                            <VSpace value={1}/>
                            <table className="charts-css column show-labels multiple show-primary-axis data-spacing-20 datasets-spacing-10 show-data-axes"
                                   id={'dashboard--wood'}>
                                <thead>
                                <tr>
                                    <th scope="col"> Jahr</th>
                                    <th scope="col"> amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    yearKeys.map(key => {
                                        const percent = years[key] / maxValue;
                                        return (
                                            <tr key={key}>
                                                <th scope="row"> {key}</th>
                                                <td style={{"--size": percent} as CSSProperties}><span className={"data"}>{years[key].toFixed(2)}</span></td>
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>

                            </table>
                        </TileNoValue>
                    </div>
                </div>

            </Content>
        </>
    );
};
