import {FC, ReactElement, useState} from "react";
import {usePartnerListQuery} from "../../configure/api/businesspartner-api";
import {$PartnerData, PartnerData} from "../../models/partner.data";
import {$String} from "../../utils/string";
import {SelectableCell} from "../UI/selectable-cell/SelectableCell";
import {VSpace} from "../UI/spacer/Spacer";
import {Input} from "../UI/input/Input";
import {Headline} from "../UI/headline/Headline";
import {Highlight} from "../highlight/Highlight";

type props = {
    label?: string,
    empty?: string,
    onEnd: (uid: number) => void
};

export const ListOfPartnersSelection: FC<props> = ({empty, label, onEnd}): ReactElement => {
    const [search, setSearch] = useState<string>('');
    const $customers = usePartnerListQuery();

    const drawItem = (item: PartnerData) => {
        const name = $String.combine(item, 'salutation title firstname lastname');

        return (
            <SelectableCell onClick={() => onEnd(item.uid)} key={item.uid}>
                <Highlight search={search} text={name}/>
            </SelectableCell>
        );
    };

    const list = ($customers?.data || []).filter(item => {
        if (!$PartnerData.isMaerkner(item)) {
            return false;
        }
        if (search.trim().length === 0) {
            return true;
        }
        const name = $String.combine(item, "firstname lastname");
        return name.toUpperCase().indexOf(search.toUpperCase()) !== -1;
    });

    return (
        <>
            {label && <><Headline>{label}</Headline><VSpace value={0.5}/></>}
            <Input initialValue={search}
                   onChange={setSearch}
                   type={"search"}
                   placeholder={'Suche...'}/>
            <VSpace/>

            {empty &&
               <>
                  <SelectableCell onClick={() => onEnd(0)} key={'empty'}>
                      {empty}
                  </SelectableCell>
                  <VSpace/>
               </>
            }
            {list.map(drawItem)}
        </>
    );
}
