import {useGetPartnerForVirilQuery, usePartnerListQuery} from "../../configure/api/businesspartner-api";
import {Headline} from "../UI/headline/Headline";
import {Input} from "../UI/input/Input";
import {FC, ReactElement, useEffect, useState} from "react";
import {PartnerData, PartnerMaerkner, PARTNERTYPE} from "../../models/partner.data";
import {VSpace} from "../UI/spacer/Spacer";
import {SelectableCell} from "../UI/selectable-cell/SelectableCell";
import {$String} from "../../utils/string";
import {Highlight} from "../highlight/Highlight";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectUser} from "../../configure/slice/auth-slice";
import {Dot} from "../UI/dot/Dot";
import {$Quota} from "../../models/quota.data";

type props = {
    selected?: number,
    initialSearch?: string,
};

export const ListOfPartners: FC<props> = ({selected = -1, initialSearch = ''}) => {
    const isViril = (value: string): boolean => (/[A-Z][0-9]{1,3}/.test(value));

    const $user = useSelector(selectUser);
    const $navigate = useNavigate();

    const [search, setSearch] = useState<string>(initialSearch);
    const $customers = usePartnerListQuery();
    const $virilCustomers = useGetPartnerForVirilQuery(search, {skip: !isViril(search)})

    useEffect(() => {
        setSearch(initialSearch)
    }, [initialSearch]);

    /**
     * draw marks and assigned ones
     * @param sections
     * @param maerkner
     */
    const drawMarks = (sections: string[], maerkner: PartnerMaerkner) => (
        <div className={"row end"}>
            {
                sections.map((item: string) => <Dot key={item} label={item}
                                                    outline={maerkner.mark[item] === undefined}
                                                    active={maerkner.mark[item] !== undefined}/>)
            }
        </div>
    );

    /**
     * draw one customer
     * @param customer
     */
    const drawCard = (customer: PartnerData): ReactElement | null => {

        const name = customer.type === PARTNERTYPE.COMPANY ?
            $String.combine(customer, 'name') :
            $String.combine(customer, 'salutation title firstname lastname');
        const addr = $String.combine(customer, 'street zip city');

        if (!isViril(search)) {
            let hide = false;
            if (search.trim().length > 0) {
                hide = name.toUpperCase().indexOf(search.toUpperCase()) === -1;
            }

            if (hide) {
                return null;
            }
        }

        const sections = Object.keys($Quota.MARK_MAPPING);

        return (
            <SelectableCell onClick={() => $navigate(`/partner/${customer.uid}`)}
                            active={customer.uid === selected}
                            key={'card' + customer.uid}>
                <div className="row">
                    <>
                        <div>
                            <strong><Highlight search={search} text={name}/></strong><br/>
                            <Highlight search={search} text={addr}/>
                        </div>

                        {customer.type === PARTNERTYPE.MAERKNER && drawMarks(sections, customer as PartnerMaerkner)}
                    </>
                </div>
            </SelectableCell>
        );
    };

    const drawCustomers = () => {
        const viril = isViril(search);
        const ref = (viril) ? $virilCustomers.data : $customers.data;
        return (
            <>
                {viril && <p>Liste der Märkner für den Virilanteil: <strong>{search}</strong></p>}
                {ref?.map(drawCard)}
            </>);
    };

    return (
        <>
            <Headline>Suche</Headline>
            <p>Alle Geschäftspartner sind hier aufgelistet. Suche nach Partnern oder Virilanteilen.
                Für die Suche nach einem Viril Anteil z.b. G42 eingeben.
                Weitere Informationen durch Klicken auf den Namen.</p>

            {$user.canEdit &&
               <div className={"row end"}>
                  <a href="/partner/new">Geschäftspartner anlegen</a>
               </div>}

            <VSpace value={0.5}/>
            <Input initialValue={search}
                   onChange={setSearch}
                   type={"search"}
                   placeholder={'Suche...'}/>
            <VSpace/>
            {
                drawCustomers()
            }
        </>
    );
}
