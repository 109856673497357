export const $Object = {
    onlyAttributes: (obj: any, attributeList: string): any => {
        const remove: string[] = [];
        const keys = Object.keys(obj);

        const list = attributeList.split(' ');
        keys.forEach((k: string) => {
            if (list.indexOf(k) === -1) {
                remove.push(k);
            }
        });
        return $Object.removeAttributes(obj, remove.join(' '));
    },
    removeAttributes: (obj: any, removeList: string): any => {
        const payload = {...obj};
        // @ts-ignore
        removeList.split(' ').forEach((key: string) => delete payload[key]);
        return payload;
    },
    filter: (source: any[], search: any) => {
        const keys = Object.keys(search);
        return source.filter((item) => {
            return keys.every((k) => {
                if (search[k] === "") {
                    return true;
                }

                // depending on type
                if (typeof item[k] === "string") {
                    return item[k].toLowerCase().indexOf(search[k].toLowerCase()) !== -1;
                }
                if (search[k].substring(0, 1) === ">") {
                    return Number(item[k]) > Number(search[k].substring(1));
                } else if (search[k].substring(0, 1) === "<") {
                    return Number(item[k]) < Number(search[k].substring(1));
                }
                // simple numberic compare
                return Number(item[k]) === Number(search[k]);
            });
        });
    },
    dateWithoutTime: (value: number) => {
        const dt = new Date(value);
        dt.setHours(0);
        dt.setMinutes(0);
        dt.setSeconds(0);
        dt.setMilliseconds(0);
        return dt.getTime();
    }
};
