import {FC, ReactElement, useEffect, useState} from "react";
import {HelpDashboard} from "./HelpDashboard";
import {CancelButton} from "../UI/button/Button";
import {HelpPartner} from "./HelpPartner";
import "./Help.css";
import {HelpPartnerNew} from "./HelpPartnerNew";
import ReactDOM from "react-dom";
import {HelpQuota} from "./HelpQuota";
import {HelpHistory} from "./HelpHistory";
import {HelpPartnerDetails} from "./HelpPartnerDetails";
import {HelpQuotaDetails} from "./HelpQuotaDetails";

type Props = {
    show: boolean,
    onClose: () => void
};

export const Help: FC<Props> = ({show, onClose}) => {

    const [data, setData] = useState<ReactElement | null>(null);

    useEffect(() => {
        const path = window.location.pathname;

        if (path === '/dashboard') {
            setData(<HelpDashboard/>);
        } else if (path === '/partner') {
            setData(<HelpPartner/>);
        } else if (path === '/partner/new') {
            setData(<HelpPartnerNew/>);
        } else if (path.match(/\/partner\/[0-9]+/)) {
            setData(<HelpPartnerDetails/>);
        } else if (path.match(/\/quota\/[0-9]+\/edit/)) {
            setData(<HelpQuotaDetails/>);
        } else if (path === '/quota') {
            setData(<HelpQuota/>);
        } else if (path === '/history') {
            setData(<HelpHistory/>);
        } else {
            setData(<em>Keine Hilfe vorhanden</em>)
        }
    }, [window.location.pathname, show]);

    if (!show) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className="help">

            <div className="row end">
                <CancelButton label={"Schließen"} onClick={onClose}/>
            </div>

            {data}
        </div>,
        document.body
    );
}
