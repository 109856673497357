import {FC, useEffect, useRef, useState} from "react";
import './Datepicker.css';
import {VSpace} from "../spacer/Spacer";
import {DatepickerMenu} from "./DatepickerMenu";

type props = {
    label: string,
    initialValue: number,
    onChange: (value: number) => void
};

export const Datepicker: FC<props> = ({label, initialValue, onChange}) => {

    const [open, setOpen] = useState<boolean>(false);
    const ref = useRef<any>();

    const expand = () => setOpen(true);
    const close = () => setOpen(false);

    const handleClickOutside = (event: any) => {
        if (ref && ref.current && !ref.current.contains(event.target)) {
            close();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        };
    }, []);


    return (
        <div className="datepicker" ref={ref}>
            <div className="datepicker--input" tabIndex={0} onClick={expand}>
                <label>{label}</label>
                <div className="row">
                    {new Date(initialValue).toLocaleDateString()}
                    <div>ꜜ</div>
                </div>
            </div>
            {
                open &&
               <div className="datepicker--menu">
                  <DatepickerMenu initialValue={initialValue} onClick={(value) => {
                      onChange(value);
                      close();
                  }
                  }/>
               </div>
            }
        </div>
    );
};
