import {ReactElement, useState} from "react";
import {de} from "date-fns/locale";
import {Menu} from "../../components/menu/Menu";
import {Content} from "../../components/content/Content";
import {Headline} from "../../components/UI/headline/Headline";
import {VSpace} from "../../components/UI/spacer/Spacer";
import {HistoryData} from "../../models/history.data";
import {useListHistoryQuery} from "../../configure/api/history-api";
import {format, formatDistanceToNow} from "date-fns";
import {SelectableCell} from "../../components/UI/selectable-cell/SelectableCell";
import {FilterSelect} from "../../components/UI/filter-select/FilterSelect";
import './History.css';

export const History = () => {
    const [limit, setLimit] = useState<number>(20);
    const [filter, setFilter] = useState<number>(1);
    const $history = useListHistoryQuery({filter, limit});

    /**
     * draw log item row
     * @param item
     */
    const drawItem = (item: HistoryData): ReactElement => (
        <SelectableCell dark key={item.time + '-' + item.name}>
            <div className="history">
                <div className="history--date">
                    <small>{format(new Date(item.time), 'd.MMMM.yyyy HH:mm:ss', {locale: de})}</small><br/>
                    {formatDistanceToNow(item.time, {locale: de, addSuffix: true})}
                </div>
                <div className="history--name">
                    {item.name}
                </div>
                <div className="history--text">
                    {item.data}
                </div>
            </div>
        </SelectableCell>
    );

    /**
     * draw size of log
     */
    const drawLimits = () => {
        const values = [5, 10, 20, 50, 100];

        return <FilterSelect list={values} active={limit} onChange={setLimit} />
    };

    return (
        <>
            <Menu/>
            <Content>
                <Headline>Protokolldaten</Headline>
                <p>Alle Aktionen werden protokolliert und hier dargestellt.</p>
                <VSpace/>
                <div className="row">
                    <div>Zeige die letzten {drawLimits()} Einträge.</div>
                    <div>
                        <FilterSelect list={[0, 1]} active={filter}labels={['Alles','ohne Anmeldung']} onChange={setFilter} />
                    </div>
                </div>
                <VSpace/>
                {$history?.data?.map(drawItem)}
            </Content>
        </>
    );
};
