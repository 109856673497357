import {Content} from "../../components/content/Content";
import {Menu} from "../../components/menu/Menu";
import {Headline} from "../../components/UI/headline/Headline";

export const Changelog = () => (
    <>
        <Menu/>
        <Content>
            <Headline>Übersicht der Änderungen</Headline>

            <h4>23.10.2023</h4>
            <ul>
                <li>Anteile in Geschäftspartneransicht umsortiert.</li>
            </ul>

            <h4>20.10.2023</h4>
            <ul>
                <li>Dashboard zeigt nun die Übersicht über die Märkner</li>
                <li>Mobile Geräte sehen nur die Visitenkarte</li>
            </ul>

            <h4>16.10.2023</h4>
            <ul>
                <li>Visitenkarte für Anmeldung</li>
                <li>Migration der User erweitert</li>
                <li>Kontakt email geändert</li>
                <li>Datenauskunft Doppelte Anrede korrigiert</li>
                <li>Anzeige bei den Anteilen korrigiert.</li>
                <li>Download korrigiert</li>
                <li>Hilfe hinzugefügt für die einzelnen Bereiche</li>
            </ul>


            <h4>27.12.2022</h4>
            <ul>
                <li>Holzverkäufe: Gesamtsumme der Marken</li>
                <li>Holzverkäufe: Vorbelegung des Datums auf Heute, Holzart Fichte und Einheit RM</li>
                <li>Holzverkäufe: Export vervollständigt</li>
            </ul>

            <h4>26.12.2022</h4>
            <ul>
                <li>Umstellung Formulare und Validierung</li>
                <li>Holzverkäufe: Fehler bei Datumsauswahl behoben.</li>
                <li>Holzverkäufe: Bereichsauswahl bei den Holzbelegen</li>
                <li>Holzverkäufe: Vordefinierte Filter hinzugefügt</li>
                <li>Holzverkäufe: Datumskorrektur aus Exceldatei</li>
                <li>Holzverkäufe: Neue Belege und Bearbeiten</li>
                <li>Holzverkäufe: Export als CSV</li>
            </ul>

                <h4>19.12.2022</h4>
            <ul>
                <li>Protokolldateiansicht zeigt nun als Standard 20 Einträge</li>
                <li>Geschäftspartner anlegen und bearbeiten: Märkner und Firmen</li>
                <li>Anzeige Holzverkäufe als Liste mit Filterung</li>
            </ul>

            <h4>06.12.2022</h4>
            <ul>
                <li>REST API für Kundendatenänderungen verbessert</li>
                <li>In den Kundendaten den Titel korrigiert beim Bearbeiten</li>
                <li>Virialanteile werden aktualisiert beim hinzufügen/entfernen</li>
                <li>kleinere optische Änderungen bei der Datenauskunft</li>
                <li>kleinere optische Änderungen beim Kontakt</li>
                <li>kleinere optische Änderungen bei den Märknerdetails</li>
                <li>Märkner kann nur gelöscht werden wenn kein Nießbrauch definiert ist.</li>
            </ul>

            <h4>02.12.2022</h4>
            <ul>
                <li>Das eigene Passwort kann geändert werden</li>
                <li>Umsatzsteuer ID hinzugefügt</li>
                <li>Changelog hinzugefügt</li>
                <li>Leere Seite überarbeitet</li>
                <li>Abmelden ist nun über das Menü möglich</li>
                <li>CSV Export um Felder erweitert: brief, anrede, titel, strasse, plz, stadt</li>
                <li>Datenauskunft beinhaltet alle Informationen</li>
                <li>Fehlerseite überarbeitet</li>
            </ul>

            <h4>20.11.2022</h4>
            <ul>
                <li>Erste Version mit allen bisherigen Funktionen</li>
                <li>Vorbereitung für Datenauskunft</li>
                <li>Nießbrauch</li>
                <li>Sitzungstimeout auf 30 Minuten erhöht</li>
            </ul>
        </Content>
    </>
);
