import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {actionAuth, selectUser} from "../../configure/slice/auth-slice";
import logo from '../../assets/logo.jpg';
import {VSpace} from "../../components/UI/spacer/Spacer";
import {Link} from "react-router-dom";
import './ErrorHandler.css';

export const ErrorHandler = () => {
    const $user = useSelector(selectUser);
    const dispatch = useDispatch();

    const mapping = {
        '408': {
            title: 'Sitzung abgelaufen',
            message: 'Nach 30 Minuten Inaktivität wird diese aus Sicherheitsgründen beendet. Bitte erneut anmelden.'
        },
        '401': {title: 'Zugriff verweigert', message: 'Die Berechtigung für diese Anfrage ist nicht ausreichend. '}
    };

    // @ts-ignore
    const match: any | undefined = mapping[$user.error.toString()];

    if (match) {
        return <div className={"error--handler"}>
            <div className="error--handler-bg">
                <div className="error--handler-container">
                    <img src={logo} alt={"markenverband"}/>
                    <VSpace/>
                    <div className="error--handler-title">{match.title}</div>
                    <VSpace/>
                    <div className="error--handler-message">{match.message}</div>
                    <VSpace/>

                    <Link to={'/'} onClick={() => dispatch(actionAuth.reset())}>Anmeldung</Link>
                </div>
            </div>
        </div>
    }

    return null;
}
