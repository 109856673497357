import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {$Http} from "../../utils/http";
import {$WoodSale, WoodsaleData} from "../../models/woodsale.data";
import {UsufructData} from "../../models/usufruct.data";
import {BASEURL} from "./baseurl";

export const woodApi = createApi({
    reducerPath: 'woodApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASEURL,
        prepareHeaders: $Http.prepareHeaders,
    }),
    tagTypes: ['list', 'meta'],
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        woodSalesList: builder.query<any, void>({
            query: () => ({
                url: '/wood',
                method: 'GET',
            }),
            transformResponse: (response: WoodsaleData[]) => response.map($WoodSale.create),
            providesTags: ["list"]
        }),
        woodSalesMeta: builder.query<any, void>({
            query: () => ({
                url: '/wood/filterdata',
                method: 'GET',
            }),
            providesTags: ["meta"]
        }),
        woodSalesTypesAndUnits: builder.query<any, void>({
            query: () => ({
                url: '/wood/types_units',
                method: 'GET',
            }),
            providesTags: ["list"]
        }),
        woodSalesById: builder.query<any, number>({
            query: (id) => ({
                url: `/wood/${id}`,
                method: 'GET',
            }),
        }),
        woodSaleCreate: builder.mutation<any, WoodsaleData>({
            query: (body) => ({
                url: `/wood`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["list", "meta"]
        }),
        woodSaleUpdate: builder.mutation<any, {id: number, body: WoodsaleData}>({
            query: ({id, body}) => ({
                url: `/wood/${id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["list", "meta"]
        }),
        woodSaleDownload: builder.mutation<any, number[]>({
            query: (list) => ({
                url: '/wood/export',
                method: 'POST',
                body: {list},
                responseHandler: 'text'
            }),
        }),
    }),
});

export const {
    useWoodSalesListQuery,
    useWoodSalesMetaQuery,
    useLazyWoodSalesMetaQuery,
    useWoodSalesTypesAndUnitsQuery,
    useWoodSaleCreateMutation,
    useWoodSaleUpdateMutation,
    useLazyWoodSalesByIdQuery,
    useWoodSaleDownloadMutation
} = woodApi;

