import React, {FC, useState} from "react";
import {Card} from "../../../components/UI/card/Card";
import {VirilData} from "../../../models/quota.data";
import {usePartnerListQuery, useGetPartnerQuery} from "../../../configure/api/businesspartner-api";
import {useGetUsufructForQuery, useSetUsufructForMutation} from "../../../configure/api/usufruct-api";
import {$Usufruct, UsufructData} from "../../../models/usufruct.data";
import {SelectableCell} from "../../../components/UI/selectable-cell/SelectableCell";
import {$String} from "../../../utils/string";
import {Store} from "react-notifications-component";
import {ListOfPartnersSelection} from "../../../components/sidebars/ListOfPartnersSelection";

type props = {
    uid: number,
};

export const UsufructAssign: FC<props> = ({uid}) => {
    const $user = useGetPartnerQuery(uid, {skip: uid === -1});
    const $customers = usePartnerListQuery();
    const $usufruct = useGetUsufructForQuery(uid, {skip: uid === -1});
    const [data, setData] = useState<UsufructData>($Usufruct.create());
    const [showSelection, setShowSelection] = useState<boolean>(false);

    const [$save] = useSetUsufructForMutation();
    const list: VirilData[] | undefined = $user?.data?.list ?? [];

    /**
     * this is called from outside (quotaedit) when user selects customer
     */
    const onSelectionDone = (id: number) => {
        const body = {...data, usufruct: id};

        setShowSelection(false);
        setData($Usufruct.create());
        $save({uid, body}).unwrap().then(() => {
            Store.addNotification({
                message: "Nießbrauch erfolgreich durchgeführt.",
                type: "success",
                container: "top-right",
                dismiss: {
                    duration: 4000,
                }
            });
        })
            .catch(response => {
                Store.addNotification({
                    message: response.data.error,
                    type: "danger",
                    container: "top-right",
                    dismiss: {
                        duration: 4000,
                    }
                });
            });
    };


    /**
     * draw quota part
     * @param item
     */
    const drawItem = (item: VirilData) => {
        const customer = $Usufruct.usufructFor($usufruct?.data || [], $customers?.data || [], item.mid);

        return (
            <SelectableCell key={`uf-${item.mid}`} onClick={() => {
                setData({...data, mid: item.mid});
                setShowSelection(true);
            }} active={item.mid === data.mid}>
                Für <strong>{item.mid}</strong>{' '}
                {customer === null && 'ist kein Nießbrauch eingetragen.'}
                {customer !== null && `hat der Märkner ${$String.combine(customer, 'firstname lastname')} ein Nießbrauchsrecht.`}
            </SelectableCell>
        );
    };

    const title = showSelection ? `Nießbrauch für ${data.mid}` : 'Nießbrauch';

    return (
        <Card title={title}>
            <>
                {
                    showSelection ?
                        <ListOfPartnersSelection empty="Kein Nießbrauch"
                                                 onEnd={onSelectionDone}/>
                        :
                        list.map(drawItem)
                }
            </>
        </Card>
    );
};
