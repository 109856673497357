import {ReactElement, useEffect, useState} from "react";
import {Menu} from "../../components/menu/Menu";
import {Content} from "../../components/content/Content";
import {EmptyContent} from "../../components/empty-content/EmptyContent";
import {$Quota} from "../../models/quota.data";
import {HSpace, VSpace} from "../../components/UI/spacer/Spacer";
import {Headline} from "../../components/UI/headline/Headline";
import {$String} from "../../utils/string";
import {usePartnerListQuery, useDownloadMutation} from "../../configure/api/businesspartner-api";
import {$PartnerData, PartnerMaerkner} from "../../models/partner.data";
import {SelectableCell} from "../../components/UI/selectable-cell/SelectableCell";
import {CancelButton, OutlineButton, PrimaryButton} from "../../components/UI/button/Button";
import {Highlight} from "../../components/highlight/Highlight";
import {Card} from "../../components/UI/card/Card";
import {ListOfQuota} from "../../components/sidebars/ListOfQuota";
import {$QuotaFilter, QuotaFilter} from "../../models/quotafilter.data";
import {Store} from "react-notifications-component";
import './Quota.css';

export const Quota = (): ReactElement => {
    const $customers = usePartnerListQuery();
    const marks = Object.keys($Quota.MARK_MAPPING);
    const [filter, setFilter] = useState<QuotaFilter>($QuotaFilter.create());
    const [dataList, setDataList] = useState<PartnerMaerkner[]>([]);

    const [selected, setSelected] = useState<number[]>([]);
    const [filteredList, setFilteredList] = useState<PartnerMaerkner[]>([])

    const [$download] = useDownloadMutation();

    useEffect(() => {

        const onlyMarkner = ($customers?.data ?? []).filter($PartnerData.isMaerkner) as PartnerMaerkner[];

        setDataList(onlyMarkner);
        setFilteredList(onlyMarkner);
    }, [$customers?.data]);

    /**
     * toggle selected items
     * @param uid
     */
    const toggleSelection = (uid: number) => {
        const list = [...selected];
        const id = list.indexOf(uid);
        if (id === -1) {
            list.push(uid);
        } else {
            list.splice(id, 1);
        }
        setSelected(list);
    }

    /**
     * filter data
     * @param obj
     * @param optionalList
     */
    const filterCustomer = (obj: QuotaFilter, optionalList: PartnerMaerkner[] | null = null) => {
        const filterMarks = Object.keys(obj.mark).filter(key => obj.mark[key]);

        const list = (optionalList ? optionalList : dataList).filter((user: PartnerMaerkner) => {
            const keys = Object.keys(user.mark);

            // filter by mark
            if (!filterMarks.some((key) => keys.indexOf(key) !== -1)) {
                return false;
            }

            if (obj.search.trim().length === 0) {
                return true;
            }
            const name = $String.combine(user, 'firstname lastname');
            return name.toUpperCase().indexOf(obj.search.toUpperCase()) !== -1;
        });

        setFilter(obj);
        setFilteredList(list);

        console.log('LISTE', list);
    };

    const onDowload = () => {
        if (selected.length === 0) {
            Store.addNotification({
                message: "Es muss mindestens ein Märkner in der Liste ausgewählt werden.",
                type: "danger",
                container: "top-right",
                dismiss: {
                    duration: 4000,
                }
            });
        } else {
            $download(selected)
                .unwrap()
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `export.csv`);
                    document.body.appendChild(link);
                    link.click();
                    link?.parentNode?.removeChild(link);
                })
                .catch(() => {
                    Store.addNotification({
                        message: "Download fehlgeschlagen.",
                        type: "danger",
                        container: "top-right",
                        dismiss: {
                            duration: 4000,
                        }
                    });
                });
        }
    };


    /**
     * draw customer in selectablecell
     * @param item
     * @param index
     */
    const drawCustomer = (item: PartnerMaerkner, index: number) => {
        const name = $String.combine(item, 'firstname lastname');
        return (
            <SelectableCell onClick={() => toggleSelection(item.uid)}
                            dark
                            active={selected.indexOf(item.uid) !== -1}
                            key={`row-${name}-${index}`}>

                <div className={'row vcenter'}>
                    <div className="width-40 quota--item-name"><Highlight search={filter.search} text={name}/></div>
                    <HSpace value={0.5}/>
                    {
                        marks.map((key: string, index: number) => (
                            <div className={"width-15 align-right"} key={`mark-${name}-${key}-${index}`}>
                                <div className="quota--item-quota">{$Quota.MARK_MAPPING[key]}</div>
                                <div>{$Quota.displayMarkQuota(item.mark[key])}</div>
                            </div>
                        ))
                    }
                </div>
            </SelectableCell>
        );
    };

    const onSort = (order: string) => () => {
        let list = [...dataList];
        if (order === 'name-asc') {
            list = list.sort((a, b) => a.lastname.localeCompare(b.lastname));
        } else if (order === 'name-desc') {
            list = list.sort((a, b) => b.lastname.localeCompare(a.lastname));
        } else if (order === 'quota-asc') {
            list = list.sort((a, b) => $PartnerData.totalQuota(a) - $PartnerData.totalQuota(b));
        } else if (order === 'quota-desc') {
            list = list.sort((a, b) => $PartnerData.totalQuota(b) - $PartnerData.totalQuota(a));
        }
        setDataList(list);
        filterCustomer(filter, list);
    };

    /**
     * draw table of customers
     */
    const drawTable = () => {

        if ($customers.isError ||
            $customers?.data?.length === 0) {
            return <EmptyContent/>;
        }

        return (
            <>
                <Card>
                    <div className="row start">
                        <PrimaryButton label={'Download'} onClick={onDowload}/>
                        <HSpace value={0.1}/>
                        <OutlineButton label={'Alles auswählen'}
                                       onClick={() => setSelected((filteredList ?? []).map(item => item.uid))}/>
                        <HSpace value={0.1}/>
                        <OutlineButton label={'Nichts auswählen'}
                                       onClick={() => setSelected([])}/>
                    </div>

                    <VSpace/>
                    <div className={"row start vcenter"}>
                        <strong>Sortieren nach: </strong>
                        <CancelButton label={'A-Z'} onClick={onSort('name-asc')}/>
                        <HSpace value={0.1}/>
                        <CancelButton label={'Z-A'} onClick={onSort('name-desc')}/>
                        <HSpace value={0.1}/>
                        <CancelButton label={'1..n Anteile'} onClick={onSort('quota-asc')}/>
                        <HSpace value={0.1}/>
                        <CancelButton label={'n..1 Anteile'} onClick={onSort('quota-desc')}/>
                    </div>
                </Card>
                <div className="quota--export-filter">
                    {$QuotaFilter.toString(filter)}<br/>
                    {filteredList.length} Ergebnisse. {selected.length} ausgewählt.
                </div>
                <VSpace/>
                {
                    filteredList.map(drawCustomer)
                }
            </>
        );
    };

    return (
        <>
            <Menu/>
            <Content list={<ListOfQuota list={filteredList}
                                        filter={filter}
                                        onFilter={filterCustomer}/>}>
                <Headline>Anteile</Headline>
                <VSpace/>
                {drawTable()}
            </Content>
        </>
    );
}
