import {combineReducers, configureStore, isRejectedWithValue, Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {authApi} from "./api/auth-api";
import {businesspartnerApi} from "./api/businesspartner-api";
import {historyApi} from "./api/history-api";
import authReducer, {actionAuth} from "./slice/auth-slice";
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";
import {dashboardApi} from "./api/dashboard-api"; // defaults to localStorage for web
import {quotaApi} from "./api/quota-api";
import {usuFructApi} from "./api/usufruct-api";
import {adminApi} from "./api/admin-api";
import {woodApi} from "./api/wood-api";

const authConfig = {
    key: '$auth',
    storage
};

/**
 * error middleware for 401, 408 handling
 * @param api
 */
const errorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            const {status} =  (action?.payload);
            if (status === 401 || status === 408) {
                api.dispatch(actionAuth.error(status));
            }
        }

        return next(action)
    }

export const rootReducer = combineReducers({
    [authApi.reducerPath]: authApi.reducer,
    [businesspartnerApi.reducerPath]: businesspartnerApi.reducer,
    [historyApi.reducerPath]: historyApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [quotaApi.reducerPath]: quotaApi.reducer,
    [usuFructApi.reducerPath]: usuFructApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [woodApi.reducerPath]: woodApi.reducer,

    user: persistReducer(authConfig, authReducer),
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }).concat([
        authApi.middleware,
        businesspartnerApi.middleware,
        historyApi.middleware,
        dashboardApi.middleware,
        quotaApi.middleware,
        usuFructApi.middleware,
        adminApi.middleware,
        woodApi.middleware,
        errorLogger
    ])
});

let persistor = persistStore(store);
export {store, persistor};

export type RootState = ReturnType<typeof store.getState>;
