import {FC, useEffect, useRef, useState} from "react";
import './Dropdown.css';

export interface DropdownItem {
    label: string,
    value: any
}

export const createFromStringArray = (list: string[]): DropdownItem[] => list.map(item => ({label: item, value: item}));

type props = {
    label: string,
    initialValue: any,
    list: DropdownItem[],
    addEmpty?: string,
    error?: string,
    onChange: (value: any) => void
};

export const Dropdown: FC<props> = ({label, error, addEmpty = '', initialValue, list, onChange}) => {

    const [open, setOpen] = useState<boolean>(false);
    const ref = useRef<any>();

    const expand = () => setOpen(true);
    const close = () => setOpen(false);

    const handleClickOutside = (event: any) => {
        if (ref && ref.current && !ref.current.contains(event.target)) {
            close();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        };
    }, [handleClickOutside]);

    const displayInitial = () => {
        if (initialValue === undefined) {
            return <>&nbsp;</>;
        }
        const id = list.findIndex((item: DropdownItem) => item.value === initialValue);
        if (id !== -1) {
            return list[id].label;
        }
        return <>&nbsp;</>;
    };

    return (
        <div className={'dropdown'} ref={ref}>
            <div className={`dropdown--input  ${error ? 'error' : ''}`} tabIndex={0} onClick={expand}>
                <label>{label}</label>
                <div className="row">
                    {displayInitial()}
                    <div>ꜜ</div>
                </div>
            </div>
            {(error && !open) && <div className='formular--error'>{error}</div>}
            {
                open &&
               <div className="dropdown--menu">
                   {addEmpty.length > 0 && <div key={'empty'}
                                                aria-hidden
                                                className="dropdown--menu-item"
                                                onClick={() => {
                                                    onChange('');
                                                    close();
                                                }}>{addEmpty}</div>}
                   {
                       list.map((item, index) => (<div key={item.label + index}
                                                       aria-hidden
                                                       className="dropdown--menu-item"
                                                       onClick={() => {
                                                           onChange(item.value);
                                                           close();
                                                       }}>{item.label}</div>))
                   }
               </div>
            }
        </div>
    )
        ;
};
