import React from "react";

export const HelpPartnerNew = () => (
    <>
        <h1>Geschäftspartner anlegen</h1>
        <p>Hier kannst Du Geschäftspartner anlegen, wobei ein Partner Märkner oder eine Firma sein kann. Je nach Partnertyp sind andere Eingaben notwendig.<br/>
        Die Daten können später bearbeitet werden. </p>

        <strong>Beispiel</strong><br/>
        <img src="/help/help-partner-create.gif" alt="Neu" width="100%"/>
    </>
);
