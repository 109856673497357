import {FC, useState} from "react";
import {getDaysInMonth} from "date-fns";

const MONTHNAMES = "Januar Februar März April Mai Juni Juli August September Oktober November Dezember".split(
    " "
);

type Props = {
    initialValue: number,
    onClick: (value: number) => void
}

export const DatepickerMenu: FC<Props> = ({initialValue, onClick}) => {
    const [data, setData] = useState<Date>(new Date(initialValue));

    const changeMonth = (by: number = 1) => {
        const n = new Date(data);
        n.setMonth(data.getMonth() + by);
        setData(n);
    };

    const changeYear = (by: number = 1) => {
        const n = new Date(data);
        n.setFullYear(data.getFullYear() + by);
        setData(n);
    };

    const days = Array(getDaysInMonth(data))
        .fill("", 0)
        .map((value, index) => index + 1);

    return <div className="datepicker--calendar">
        <div className="datepicker--calendar-month">
            <div className="datepicker--calendar-month-head">
                <div aria-hidden className="datepicker--calendar-nav" onClick={() => changeYear(-1)}>«</div>
                <div aria-hidden className="datepicker--calendar-nav" onClick={() => changeMonth(-1)}>‹</div>
                <strong>{MONTHNAMES[data.getMonth()]} {data.getFullYear()}</strong>
                <div aria-hidden className="datepicker--calendar-nav" onClick={() => changeMonth()}>›</div>
                <div aria-hidden className="datepicker--calendar-nav" onClick={() => changeYear()}>»</div>
            </div>
            <div className={"row start wrap"}>
                {days.map((day) => {
                    const source = new Date(data.getFullYear(), data.getMonth(), day);
                    const dest = new Date();
                    const isToday = source.toLocaleDateString() === dest.toLocaleDateString();
                    const isCurrent = source.toLocaleDateString() === new Date(initialValue).toLocaleDateString();
                    return (
                        <div key={`month-${data.getMonth()}-${day}`}
                             aria-hidden
                             onClick={() => {
                                 onClick(source.getTime());
                             }}
                             className={`datepicker--calendar-day ${isToday ? 'today' : ''} ${isCurrent ? 'current' : ''}`}>
                            {day}
                        </div>
                    )
                })}
            </div>
        </div>
    </div>
}
