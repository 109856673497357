import React from "react";

export const HelpPartnerDetails = () => (
    <>
        <h1>Geschäftspartner bearbeiten</h1>
            <p>Hier kannst Du alle Details eines Geschäftspartner bearbeiten. Die Details sind in einzelnen Bereiche unterteilt:<br/>
            <ul>
                <li>Persönliche Daten</li>
                <li>Erbengemeinschaft</li>
                <li>Kontakt</li>
                <li>Notiz</li>
                <li>Anteile</li>
            </ul>
                Um einen der Bereiche zu bearbeiten auf <strong>Bearbeiten</strong> klicken. Der Bereich bietet dann
                eine Möglichkeit für die Bearbeitung an.
            </p>

        <p>Um einem Geschäftspartner Auskunft zu geben, ist es möglich eine Datenauskunft zu erstellen. Hierfür einfach auf die Schaltfläche rechts oben klicken.
        Die Datenauskunft öffnet sich in einem neuen Fenster.</p>

            <p>
                Sind keine Anteile vorhanden, dann ist es auch möglich, den Geschäftspartner zu löschen.
                Da dies ein unwiderruflich ist, muss vorher ein Bestätigungscode eingegeben werden.
            </p>

        <strong>Beispiel</strong><br/>
        <img src="/help/help-partner-delete.gif" alt="Löschen" width="100%"/>
    </>
);
