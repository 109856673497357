import {$String} from "../utils/string";
import {$Fields} from "./fields.data";

export interface PartnerQuotaMark {
    [key: string]: number
}

export const PARTNERTYPE = {
    MAERKNER: 'm',
    COMPANY: 'c'
};

export interface PartnerData {
    uid: number
    note: string,
    street: string,
    zip: string,
    city: string,
    taxid: string,

    type: string    // see PARTNERTYPE
}

export interface PartnerMaerkner extends PartnerData {
    email: string,
    firstname: string,
    heritage: string,
    lastname: string,
    letter: string,
    phone: string,
    phone_fax: string,
    phone_mobile: string,
    salutation: string,
    title: string,
    mark: PartnerQuotaMark,

    [key: string]: string | number | PartnerQuotaMark
}

export interface PartnerCompany extends PartnerData {
    name: string,
    website: string
}

/**
 * create base
 * @param obj
 */
const _base = (obj: any = {}): PartnerData => ({
    type: obj.type ?? PARTNERTYPE.MAERKNER,
    note: obj.note ?? '',
    city: obj.city ?? '',
    street: obj.street ?? '',
    taxid: obj.taxid ?? '',
    uid: obj.uid ?? '',
    zip: obj.zip ?? ''
});

const _maerkner = (obj: any): PartnerMaerkner => ({
    ..._base(obj),
    email: obj.email ?? '',
    firstname: obj.firstname ?? '',
    heritage: obj.heritage ?? '',
    lastname: obj.lastname ?? '',
    letter: obj.letter ?? '',
    phone: obj.phone ?? '',
    phone_fax: obj.phone_fax ?? '',
    phone_mobile: obj.phone_mobile ?? '',
    salutation: obj.salutation ?? '',
    title: obj.title ?? '',
    mark: obj.mark ?? {},
});

const _company = (obj: any = {}): PartnerCompany => ({
    ..._base(obj),
    name: obj.name ?? '',
    website: obj.website ?? '',
});

export const $PartnerData = {
    create: (obj: any = {}): PartnerData => {
        if (obj.type === PARTNERTYPE.COMPANY) {
            return _company(obj);
        }

        return _maerkner(obj);
    },
    getByUid: (list: PartnerData[] | undefined, id: number) => (list ?? []).find((item: PartnerData) => item.uid === id),
    isMaerkner: (obj: PartnerData): boolean => (obj?.type === PARTNERTYPE.MAERKNER),
    mapLink: (item: PartnerData | undefined): string => {
        let link = 'https://www.google.de/maps/place/';
        if (item) {
            link += item.street.replace(/ /g, '+');
            link += ',';
            link += $String.combine(item, 'zip city').trim().replace(/ /g, '+');
        }
        return link;
    },
    hasContact: (item: PartnerData | undefined): boolean => {
        if (!item) {
            return false;
        }

        if (item.type === PARTNERTYPE.COMPANY) {
            return (item as PartnerCompany).website.trim().length > 0;
        }

        return (
            // @ts-ignore
            ['phone', 'phone_fax', 'phone_mobile', 'email'].some((key: string) => item[key].trim().length > 0)
        );
    },
    summaryMark: (list: PartnerData[]): PartnerQuotaMark => {

        // jump out if not all maerkner
        if (list.some(obj => obj.type === PARTNERTYPE.COMPANY)) {
            return {};
        }

        const ret: PartnerQuotaMark = {};
        list.forEach((item: PartnerData) => {
            const maerkner = (item as PartnerMaerkner);
            const keys = Object.keys(maerkner.mark);
            keys.forEach((key: string) => {
                if (ret[key] === undefined) {
                    ret[key] = 0;
                }
                ret[key] += maerkner.mark[key];
            })
        });
        return ret;
    },
    totalQuota: (item: PartnerData): number => {
        if (item.type === PARTNERTYPE.COMPANY) {
            return 0;
        }
        const maerkner = (item as PartnerMaerkner);
        return Object.keys(maerkner.mark).reduce((acc, cur) => acc + maerkner.mark[cur], 0)
    },
    fieldsPerson: [
        $Fields.create('Briefanrede', 'letter'),
        $Fields.create('Titel', 'title'),
        $Fields.create('Anrede', 'salutation'),
        $Fields.create('Vorname', 'firstname', 50, false),
        $Fields.create('Nachname', 'lastname', 50),
        $Fields.create('Strasse', 'street'),
        $Fields.create('Postleitzahl', 'zip', 20, false),
        $Fields.create('Ort', 'city', 80),
        $Fields.create('Umsatzsteuer ID', 'taxid')
    ],
    fieldsCompany: [
        $Fields.create('Name', 'name', 100),
        $Fields.create('Strasse', 'street'),
        $Fields.create('Postleitzahl', 'zip', 20, false),
        $Fields.create('Ort', 'city', 80),
        $Fields.create('Umsatzsteuer ID', 'taxid')
    ],
    fieldsContact: [
        $Fields.create('Email', 'email'),
        $Fields.create('Telefon', 'phone'),
        $Fields.create('Fax', 'phone_fax'),
        $Fields.create('Mobil', 'phone_mobile'),
    ],
    fieldsContactCompany: [
        $Fields.create('Webseite', 'website'),
    ],
    fieldsNote: [
        $Fields.create('Notiz', 'note', 100, true, "multi"),
    ],
    fieldsHeritage: [
        $Fields.create('Hinweis', 'heritage', 100, true, "multi"),
    ]
};
