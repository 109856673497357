import {isToday, isTomorrow, isYesterday} from "date-fns";

const _repl = (text: string, what: string, rep: string): string => {
    let buf = text;
    while (buf.indexOf(what) !== -1) {
        buf = buf.replace(what, rep);
    }
    return buf;
};

const mapping = [
    {from: '\r', to: ''},
    {from: '\n', to: '|LF|'},
    {from: "'", to: '|SQ|'},
    {from: '"', to: '|DQ|'},
];

export const $String = {
    removeDangerous: (str: string): string => {
        let tmp = str ?? '';
        mapping.forEach((item) => {
            tmp = _repl(tmp, item.from, item.to);
        });
        return tmp;
    },
    replacePseudo: (str: string): string => {
        let tmp = str ?? '';
        mapping.forEach((item) => {
            if (item.to !== '') {
                tmp = _repl(tmp, item.to, item.from);
            }
        });
        return tmp;
    },
    combine: (item: any | undefined, joinList: string): string => {
        if (!item) {
            return '';
        }
        const args: string[] = [];
        joinList.split(' ').forEach(key => {
            // @ts-ignore
            const value = item[key];
            if (value && value.trim().length > 0) {
                args.push(value);
            }
        });
        return args.join(' ');
    },
    plural: (value: number, single: string, multi: string) => value === 1 ? single : multi,
    smartDateRangeText: (from: number, to: number): string => {
        if (isToday(from) && isToday(to)) {
            return 'Heute';
        }
        if (isToday(from) && isTomorrow((to))) {
            return 'Heute und morgen';
        }
        if (isYesterday(from) && isToday(to)) {
            return 'Gestern und heute';
        }
        return `${new Date(from).toLocaleDateString()} bis ${new Date(to).toLocaleDateString()}`;
    }
};
