import {useLazyAuthQuery, useSignInMutation} from "../../configure/api/auth-api";
import {Input} from "../../components/UI/input/Input";
import {ReactElement, useEffect, useState} from "react";
import {PrimaryButton} from "../../components/UI/button/Button";
import {useNavigate} from "react-router-dom";
import {VSpace} from "../../components/UI/spacer/Spacer";
import './SignIn.css';

export const SignIn = () => {

    const [user, setUser] = useState<string>('');
    const [pass, setPass] = useState<string>('');
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [$signIn, $signInData] = useSignInMutation();
    const [$auth] = useLazyAuthQuery();

    const navigate = useNavigate();

    useEffect(() => {
        if ($signInData.isError) {
            setTimeout(() => $signInData.reset(), 4000);
        }
    }, [$signInData]);

    const tryToSignIn = () => {
        $auth().unwrap().then((res) => {
            const token = res.id;
            $signIn({user, pass, token}).unwrap().then(() => {
                navigate('/dashboard')
            });
        })
    };

    const renderLogin = (): ReactElement | null => {
        if (!showLogin) {
            return null;
        }
        return (
            <div className={`signin--box ${$signInData.isError ? 'error' : ''}`}>
                <h1>Willkommen zurück</h1>
                <h2>Markenverband Winterberg</h2>
                <VSpace/>
                <Input label='Benutzername' onChange={setUser} initialValue={user} placeholder={'Benutzername'}/>
                <VSpace value={0.5}/>
                <Input label='Passwort' type="password" onChange={setPass} initialValue={pass}
                       placeholder={'Passwort'}/>
                <VSpace/>
                <PrimaryButton label={'Anmelden'} onClick={tryToSignIn}/>
            </div>
        );
    };

    const renderCard = (): ReactElement | null => {
        if (showLogin) {
            return null;
        }
        return (
            <div className="signin--card">

                <h1>MARKENVERBAND<br/>WINTERBERG</h1>

                <div className="signin--card-content">
                    <h3>büro</h3>
                    <div className="row">
                        <div className="width-50">
                            Am Waltenberg 54<br/>59955 Winterberg<br/>
                        </div>
                        <div className="width-50">
                            <strong>tel</strong> 02981 / 9287035<br/>
                            <strong>fax</strong> 02981 / 9287036<br/>

                        </div>
                    </div>
                    <br/>
                    <strong>iban</strong> DE56 4165 1770 0051 0111 79 / WELADED1HSL<br/>
                    <strong>mail</strong> info[at]markenverband-winterberg.de<br/>
                    <br/><br/>
                    <div className="row end no-mobile">
                        <PrimaryButton label={"Anmelden"} onClick={() => setShowLogin(true)}/>
                    </div>
                </div>
            </div>
        );
    };

    return <div className={'signin--container'}>
        {renderCard()}
        {renderLogin()}
    </div>;
}
