import {FC, PropsWithChildren, ReactElement} from "react";
import './Tag.css';

type props = {
    type: 'primary' | 'black' | 'grey' | 'outline';
};

export const Tag: FC<PropsWithChildren<props>> = ({type, children}): ReactElement => (
    <div className={`tag ${type}`}>{children}</div>
);

