import {FC, ReactElement} from "react";
import './Button.css';

type props = {
    type: 'primary' | 'delete' | 'cancel' | 'outline';
    label: string,
    disabled?: boolean,
    onClick: () => void
};

type edgeprops = Omit<props, "type">;

const Button: FC<props> = ({
                               disabled = false,
                               label = '',
                               type = 'primary',
                               onClick = () => null
                           }): ReactElement => {

    return <button className={`button ${type} ${disabled ? 'disabled' : ''}`}
                   onClick={(event) => {
                       event.preventDefault();
                       onClick();
                   }}>{label}</button>
}


export const PrimaryButton: FC<edgeprops> = ({label, disabled = false, onClick}): ReactElement => (
    <Button type={'primary'} label={label} disabled={disabled} onClick={onClick}/>
);

export const OutlineButton: FC<edgeprops> = ({label, disabled = false, onClick}): ReactElement => (
    <Button type={'outline'} label={label} disabled={disabled} onClick={onClick}/>
);

export const CancelButton: FC<edgeprops> = ({label, disabled = false, onClick}): ReactElement => (
    <Button type={'cancel'} label={label} disabled={disabled} onClick={onClick}/>
);

export const DeleteButton: FC<edgeprops> = ({label, disabled = false, onClick}): ReactElement => (
    <Button type={'delete'} label={label} disabled={disabled} onClick={onClick}/>
);
