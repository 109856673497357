import {FC, PropsWithChildren, ReactElement} from "react";
import'./Select.css';

type props = {
    active?: boolean,
    onClick: () => void
};

export const Select: FC<PropsWithChildren<props>> = ({children, active = false, onClick}): ReactElement => (
  <div aria-hidden onClick={onClick} className={`select ${active ? 'active' : ''}`}>{children}</div>
);


