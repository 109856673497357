import {FC, PropsWithChildren, ReactElement} from "react";
import {VSpace} from "../spacer/Spacer";
import './Card.css';

type props = {
    title?: string,
    onEdit?: () => void
};

export const Card: FC<PropsWithChildren<props>> = ({
                                                       children,
                                                       title,
                                                       onEdit
                                                   }): ReactElement => (
    <div className={'card'}>
        {onEdit && <div className="card--edit" onClick={onEdit}>Bearbeiten</div>}
        {title && <div className="card--title">{title}</div>}
        <VSpace value={0.3}/>
        <div className="card--content">
            {onEdit && <VSpace />}
            {children}
        </div>
    </div>
);
