import React from "react";
import {NavLink, Route, Routes, useParams} from "react-router-dom";
import {Menu} from "../../../components/menu/Menu";
import {Content} from "../../../components/content/Content";
import {QuotaAdd} from "./QuotaAdd";
import {QuotaDelete} from "./QuotaDelete";
import {VSpace} from "../../../components/UI/spacer/Spacer";
import {Headline} from "../../../components/UI/headline/Headline";
import {useGetPartnerQuery} from "../../../configure/api/businesspartner-api";
import {$String} from "../../../utils/string";
import {UsufructAssign} from "./UsufructAssign";
import {InfoSidebar} from "../../../components/sidebars/InfoSidebar";

export const QuotaEdit = () => {
    const params = useParams();
    const uid = (params.uid === undefined) ? -1 : parseInt(params.uid, 10);
    const $customer = useGetPartnerQuery(uid, {skip: uid === -1});
    const user = $String.combine($customer?.data?.user, 'firstname lastname');

    const drawSidebar = () => (
        <InfoSidebar label={'Anteile'}>
            <p>Hier können Anteile dem gewählten Märkner hinzugefügt oder entfernt werden. Hierfür einfach im
                entsprechenden Bereich den Virilanteil
                auswählen und den Anteil eingeben. </p>
            <VSpace/>
            <p>Soll einem Virilanteil ein Nießbrauch zugewiesen oder entfernt werden?
                Wähle hierzu den Virilanteil im Bereich Nießbrauch aus. Danach wird die Liste der möglichen
                Nießbrauchbenutzer angezeigt.
                Hier einen Märkner auswählen oder zum entfernen auf <strong>Kein Nießbrauch</strong> klicken.</p>
            <VSpace/>
            <NavLink className={'quota--edit-link'} to={`/quota/${uid}/edit/add`}>Anteile hinzufügen</NavLink>
            <NavLink className={'quota--edit-link'} to={`/quota/${uid}/edit/remove`}>Anteile entfernen</NavLink>
            <NavLink className={'quota--edit-link'} to={`/quota/${uid}/edit/usufruct`}>Nießbrauch</NavLink>
            <VSpace />
            <NavLink className={'quota--edit-link'} to={`/partner/${uid}`}>zurück zum Geschäftspartner</NavLink>
        </InfoSidebar>);

    return (
        <>
            <Menu/>
            <Content list={drawSidebar()}>
                <Headline>Anteile verwalten für Märkner: {user}</Headline>
                <VSpace/>

                <Routes>
                    <Route path={''} element={<QuotaAdd uid={uid}/>}/>
                    <Route path={'add'} element={<QuotaAdd uid={uid}/>}/>
                    <Route path={'remove'} element={<QuotaDelete uid={uid}/>}/>
                    <Route path={'usufruct'} element={<UsufructAssign uid={uid}/>}/>
                </Routes>
            </Content>
        </>
    );


}
