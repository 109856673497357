import {FC, PropsWithChildren, ReactElement} from "react";
import './SelectableCell.css';

type props = {
    onClick?: () => void,
    dark?: boolean,
    active?: boolean
}

export const SelectableCell: FC<PropsWithChildren<props>> = ({
                                                       children,
                                                       onClick = () => null,
                                                       dark = false,
                                                       active = false
                                                   }): ReactElement => (
    <div role="button"
         onClick={onClick}
         className={`selectablecell ${dark ? 'dark' : ''} ${active ? 'active' : ''}`}>{children}</div>
);
