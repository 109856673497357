import React from "react";

export const HelpPartner = () => (
  <>
    <h1>Liste der Geschäftspartner</h1>
      <p>In der Liste siehst Du alle Geschäftspartner mit Namen und Adresse. Handelt es sich um einen Märkner, dann
      wird zusätzlich angezeigt in welchen Marken er Anteile besitzt. <br/>
          <img src="/help/help-partner-mark.png" alt="Anteile" height="35"/><br/>
          Ist der Kreis gefüllt, dann hat der Geschäftspartner hier Anteile.<br/><br/>

          Über das Eingabefeld kannst Du nach Geschäftspartnern suchen. Die Suche wird direkt bei der Eingabe ausgeführt.
          Groß-/Kleinschreibung ist hierbei nicht relevant. Es wird nun nach Namen der Geschäftspartner gesucht.
          Übereinstimmungen werden in der Liste farblich hervorgehoben.
          <br/>
          <br/>
          Es kann darüber hinaus auch nach Virilanteilen gesucht werden. Hierzu einfach den Anfangsbuchstaben der Mark
          eingeben, gefolgt von der Nummer, z.B. G42. <br/>
          Alle Märkner, die Anteile daran haben, werden aufgelistet.
          <br/>
          Wenn Du auf einen Geschäftspartner klickst, dann werden die Details auf der rechten Seite angezeigt.
      </p>

      <strong>Beispiel</strong><br/>
      <img src="/help/help-partner-search.gif" alt="Suche" width="70%"/>
  </>
);
