export interface HistoryData {
    time: number,
    name: string,
    data: string,
}

export const $HistoryData = {
    create: (obj: any = {}): HistoryData => ({
        time: obj.time ? new Date(obj.time * 1000).getTime() : new Date().getTime(),
        name: obj.name ?? '',
        data: obj.data ?? '',
    }),
};
