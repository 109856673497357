import {FC, PropsWithChildren, ReactElement} from "react";
import {Headline} from "../UI/headline/Headline";

type props = {
    label: string
};

export const InfoSidebar: FC<PropsWithChildren<props>> = ({children, label}): ReactElement => (
    <>
        <Headline>{label}</Headline>
        {children}
    </>
);
