import {createSlice} from '@reduxjs/toolkit';
import {authApi} from "../api/auth-api";
import {$UserData, UserData} from "../../models/user.data";
import {RootState} from "../configure";

type UserState = {
    data: UserData
};

const initialState: UserState = {data: $UserData.create()};

const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset(state) {
            state.data = $UserData.create();
        },
        error(state, action) {
            state.data.error = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(authApi.endpoints.signIn.matchFulfilled, (state, {payload}) => {
            state.data = $UserData.create(payload);
        });

        builder.addDefaultCase((state, action) => {
            const {status} = action.payload ?? {};
            if (status >= 400) {
                state.data.error = status;
            }
        });
    }
});

export const actionAuth = authSlice.actions;
export default authSlice.reducer;
export const selectUser = (state: RootState): UserData => state.user.data;
