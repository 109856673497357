import {FC, ReactElement} from "react";
import './Input.css';

type props = {
    placeholder?: string,
    initialValue?: string,
    label?: string,
    type?: string,
    error?: string,
    onChange?: (val: string) => void,
};

export const Input: FC<props> = ({
                                     placeholder = '',
                                     label = '',
                                     initialValue = '',
                                     onChange = () => null,
                                     error,
                                     type = 'text',
                                 }): ReactElement => {

    return (
        <div className={`input ${error ? 'error' : ''}`}>
            {label && <label>{label}</label>}
            <input type={type}
                   aria-label={'Eingabefeld für die Eigenschaft ' + label}
                   value={initialValue}
                   placeholder={placeholder}
                   onChange={(ev) => onChange(ev.target.value)}/>
            {error && <div className='formular--error'>{error}</div>}
        </div>
    );
}
