import {$PartnerData, PartnerData} from "./partner.data";

const MARK_MAPPING: { [key: string]: string } = {
    'M': 'Merleheim',
    'W': 'Wernsdorf',
    'G': 'Günninghausen',
    'H': 'Haarfeld'
};

const MARK_QUOTALIMITS: { [key: string]: number } = {
    "G": 80000,
    "H": 144000,
    "M": 114000,
    "W": 112000
};

export interface VirilData {
    uid: number,
    mid: string,
    quota: number
    more: number
}

export interface VirilValue {
    mid: string,
    quota: number,
    totaleuro: number,
    euro: number
}

export interface VirilAvailable {
    mid: string,
    quota: number
}

interface VirilGroup {
    [key: string]: VirilData[]
}

export interface QuotaData {
    user: PartnerData,
    list: VirilData[]
}


export const $Quota = {
    create: (obj: any = {}): QuotaData => ({
        user: $PartnerData.create(obj.user),
        list: (obj.list ?? []).map((val: any) => ({
            uid: val.uid ? parseInt(val.uid) : 0,
            mid: val.mid ?? '',
            quota: val.quota ? parseInt(val.quota) : 0,
            more: val.more ? parseInt(val.more) : 0,
        }))
    }),
    group: (list: VirilData[]): VirilGroup => {
        const ret: any = {};

        list.forEach((item: VirilData) => {
            // @ts-ignore
            const key = $Quota.convertMarkToSmartText(item.mid.charAt(0));
            if (ret[key] === undefined) {
                ret[key] = [];
            }
            ret[key].push(item);
        });

        return ret;
    },
    convertMarkToSmartText: (mark: string): string => $Quota.MARK_MAPPING[mark] ?? '',
    displayMarkQuota: (value: number | undefined, fallback: string = '-'): string => value ? (value / 1000).toFixed(3) : fallback,
    MARK_QUOTALIMITS,
    MARK_MAPPING,
};
