
export interface FieldsData {
    label: string,
    attribute: string,
    width: number,
    padding: boolean,
    type: string,
    typeData: any,
}

export const $Fields = {
    create: (label: string,
             attribute: string,
             width = 100,
             padding = true,
             type = 'text',
             typeData = {}
             ): FieldsData => ({
        label,
        attribute,
        width,
        padding,
        type,
        typeData,
    }),
};
