import {Menu} from "../../components/menu/Menu";
import {Headline} from "../../components/UI/headline/Headline";
import {Card} from "../../components/UI/card/Card";
import {Content} from "../../components/content/Content";
import {useMemo, useState} from "react";
import {VSpace} from "../../components/UI/spacer/Spacer";
import {$Fields, FieldsData} from "../../models/fields.data";
import {useChangePasswordMutation} from "../../configure/api/admin-api";
import {$Crypt} from "../../utils/crypt";
import {Store} from "react-notifications-component";
import {FormFields, validateFilled, ValidationData} from "../../components/form-fields/FormFields";
import {useNavigate} from "react-router-dom";

interface ProfilePassword {
    password: string,
    confirm: string
}

export const Profile = () => {
    const [pass, setPass] = useState<ProfilePassword>({password: '', confirm: ''});
    const $navigate = useNavigate();
    const [$change] = useChangePasswordMutation();

    const validator: ValidationData = useMemo(() =>
        ({
            'password': (value: string) => validateFilled(value, 'Bitte Passwort eingeben'),
            'confirm': (value: string) => pass.password !== value ? 'Passwörter stimmen nicht überein' : undefined
        }), []);

    const fields: FieldsData[] = [
        $Fields.create('Passwort', 'password', 50, false, 'password'),
        $Fields.create('Passwort bestätigen', 'confirm', 50, true, 'password')
    ];

    const onChange = () => {
        $change($Crypt.crypt(pass.password)).unwrap().then(() => {
            Store.addNotification({
                message: 'Passwort erfolgreich geändert.',
                type: "success",
                container: "top-right",
                dismiss: {
                    duration: 4000,
                }
            });
            setPass({password: '', confirm: ''});
        }).catch(() => {
            Store.addNotification({
                message: 'Fehler beim Ändern des Passwortes.',
                type: "danger",
                container: "top-right",
                dismiss: {
                    duration: 4000,
                }
            });
        })
    };

    return (
        <>
            <Menu/>
            <Content>
                <Headline>Profil</Headline>
                <VSpace/>
                <Card title={'Passwort ändern'}>
                    <FormFields fields={fields}
                                submitText={'Passwort ändern'}
                                validator={validator}
                                data={pass}
                                onUpdate={(key: string, value: any) => setPass({
                                    ...pass,
                                    [key]: value
                                })}
                                onSubmit={(flag) => {
                                    if (flag) {
                                        onChange()
                                    }
                                    $navigate('/dashboard')
                                }}/>
                </Card>
            </Content>
        </>
    );
};
