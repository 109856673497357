import {FC} from "react";
import './YesNo.css';

type props = {
    initialValue: boolean,
    label: string,
    onChange: (flag: boolean) => void
};

export const YesNo: FC<props> = ({initialValue, label, onChange}) => {
    return (
        <div className="yesno--container">
            <div className="yesno">
                <div aria-hidden
                     onClick={() => onChange(true)}
                     className={`cell ${initialValue ? 'yes' : 'none'}`}>JA
                </div>
                <div aria-hidden
                     onClick={() => onChange(false)}
                     className={`cell ${!initialValue ? 'no' : 'none'}`}>NEIN
                </div>
            </div>
            {label}
        </div>
    );
}
