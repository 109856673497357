import React from "react";

export const HelpQuotaDetails = () => (
    <>
        <h1>Anteile verwalten</h1>
        <p>
            <strong>Anteile hinzufügen</strong><br/>
            Alle verfügbaren Anteilen werden angezeigt. Klicke auf einen Eintrag um einen Anteil dem ausgewählten Märkner zuzuweisen.<br/>

            <img src="/help/help-quota-add.gif" alt="Anteil hinzufügen" width="100%"/>
<br/>
<br/>
            <strong>Anteile entfernen</strong><br/>
            Wurde dem Märkner bereits ein oder mehrere Anteile zugewiesen, dann können diese auch wieder, ganz oder anteilig, entfernt werden.

            <img src="/help/help-quota-delete.gif" alt="Anteil entfernen" width="100%"/>
            <br/>
            <br/>

            <strong>Nießbrauch</strong><br/>

            Alle Anteile werden angezeigt. Um einem anderen Märkner ein Nießnrauchsrecht zuzuweisen den Anteil auswählen.
            Nun aus der Liste den Märkner wählen. Mit Hilfe der Suche kann der Märkner schneller gefunden werden.

            <img src="/help/help-quota-usufruct.gif" alt="Nießbrauch" width="100%"/>

        </p>
    </>
);
