import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {$Http} from "../../utils/http";
import {UsufructData, UsufructResponse} from "../../models/usufruct.data";
import {BASEURL} from "./baseurl";

export const usuFructApi = createApi({
    reducerPath: 'usuFructApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASEURL,
        prepareHeaders: $Http.prepareHeaders,
    }),
    keepUnusedDataFor: 1,
    tagTypes: ['usufruct', 'list'],
    endpoints: (builder) => ({
        getUsufructList: builder.query<UsufructResponse, void>({
            query: () => ({
                url: `/uf`,
                method: 'GET',
            }),
            providesTags: ['list']
        }),
        getUsufructFor: builder.query<UsufructData[], number>({
            query: (uid) => ({
                url: `/uf/${uid}`,
                method: 'GET',
            }),
            providesTags: ['usufruct']
        }),
        setUsufructFor: builder.mutation<any, { uid: number, body: UsufructData }>({
            query: ({uid, body}) => ({
                url: `/uf/${uid}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ["usufruct", "list"]
        }),
    }),
});

export const {
    useGetUsufructListQuery,
    useGetUsufructForQuery,
    useSetUsufructForMutation
} = usuFructApi;
