import React, {FC, useRef, useState} from "react";
import {$Quota, VirilAvailable} from "../../../models/quota.data";
import {Select} from "../../../components/UI/select/Select";
import {VSpace} from "../../../components/UI/spacer/Spacer";
import {Input} from "../../../components/UI/input/Input";
import {PrimaryButton} from "../../../components/UI/button/Button";
import {businesspartnerApi} from "../../../configure/api/businesspartner-api";
import {useDispatch} from "react-redux";

type props = {
    list: VirilAvailable[],
    label: string,
    onEnd: (mid: string, amount: string) => void
};

export const QuotaUserInput: FC<props> = ({label, list, onEnd}) => {
    const [data, setData] = useState<VirilAvailable>({mid: '', quota: 0});
    const [userInput, setUserInput] = useState<string>('');
    const maxPart = useRef(0);
    const dispatch = useDispatch();

    /**
     * validate user input
     */
    const validate = (): string | undefined => {
        if (maxPart.current > 0) {

            if (userInput === '') {
                return 'Bitte einen Anteil angeben.';
            }

            const value = parseFloat(userInput) * 1000;
            if (Number.isNaN(value)) {
                return 'Bitte eine Zahl eingeben.';
            }
            if (value > maxPart.current) {
                return `Anteil zu groß. Maximal ${$Quota.displayMarkQuota(maxPart.current)}.`;
            }
        }
        return undefined;
    };

    return (
        <>
            <div className="row start wrap">
                {
                    list.map((item: VirilAvailable) => (
                        <Select key={item.mid}
                                active={item.mid === data.mid}
                                onClick={() => {
                                    setData({...item});
                                    setUserInput('');
                                    maxPart.current = item.quota;
                                }}><strong>{item.mid}</strong> mit {$Quota.displayMarkQuota(item.quota)}</Select>
                    ))
                }
            </div>
            <VSpace/>
            {
                data.mid !== '' &&
               <>
                  <p>Welcher Anteil soll verwendet werden? <strong>{$Quota.displayMarkQuota(maxPart.current)} Anteile
                     verfügbar.</strong>
                  </p>
                  <VSpace/>
                  <Input label={'Anteil'} initialValue={userInput}
                         onChange={setUserInput}
                         error={validate()}/>
                  <VSpace/>
                  <div className="row end">
                      {
                          validate() === undefined &&
                         <PrimaryButton label={label} onClick={() => {
                             onEnd(data.mid, userInput);
                             setData({mid: '', quota: 0});
                             setUserInput('');
                             maxPart.current = 0;

                             // auto fetch customer data
                             dispatch(businesspartnerApi.util.invalidateTags(['partner']));
                         }}/>
                      }
                  </div>
               </>
            }
        </>
    );
}
