import {FC, ReactElement} from "react";

type props = {
    x?: number,
    y?: number,
};

const Spacer: FC<props> = ({x = 0, y = 0}): ReactElement => {
    return <div style={{
        paddingTop: `${y}rem`,
        paddingLeft: `${x}rem`
    }}/>
}

export const HSpace: FC<{ value?: number }> = ({value = 1}): ReactElement => (<Spacer x={value} y={0}/>);
export const VSpace: FC<{ value?: number }> = ({value = 1}): ReactElement => (<Spacer x={0} y={value}/>);


