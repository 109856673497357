import {$Quota, VirilData, VirilValue} from "../../../models/quota.data";
import React, {FC, Fragment, ReactElement} from "react";
import {Card} from "../../../components/UI/card/Card";
import {VSpace} from "../../../components/UI/spacer/Spacer";
import {useSelector} from "react-redux";
import {selectUser} from "../../../configure/slice/auth-slice";
import {useGetUsufructForQuery} from "../../../configure/api/usufruct-api";
import {$Usufruct} from "../../../models/usufruct.data";
import './PartnerQuota.css';

type props = {
    uid: number,
    data: VirilData[],
    values: VirilValue[],
    onShowMark: (mark: string) => void
};

const ICON = (
    <svg width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
         strokeWidth="1" viewBox="0 0 24 24">
        <path d="M2 5.957 12 9.5l10-3.543L12 2.5 2 5.957Z"></path>
        <path d="m2 10 10 3.5L22 10"></path>
        <path d="m2 14 10 3.5L22 14"></path>
        <path d="m2 18 10 3.5L22 18"></path>
    </svg>
);

export const PartnerQuota: FC<props> = ({uid, data, values, onShowMark}): ReactElement => {

    const $user = useSelector(selectUser);
    const grouped = $Quota.group(data);
    const keys = Object.keys(grouped).sort();

    const $usufruct = useGetUsufructForQuery(uid, {skip: uid === -1});


    /**
     * draw total euro value
     */
    const drawTotalSum = (): ReactElement | null => {
        if (values.length <= 1) {
            return null;
        }

        const total = values.reduce((acc, cur) => acc + cur.totaleuro, 0);
        return (
            <div className={"customer--quota"}>
                <div className="row end euro"><strong>{total.toFixed(2)} EUR</strong></div>
            </div>
        )
    };

    /**
     * draw an item
     * @param item
     */
    const drawItem = (item: VirilData) => {
        const markName = `Mark ${item.mid.substring(1)}`;
        const euro = (values.find((obj: VirilValue) => obj.mid === item.mid)?.totaleuro)?.toFixed(2);
        const hasRelation = $Usufruct.has($usufruct?.data ?? [], item.mid);

        if (item.more === 0) {
            return (
                <div className={"customer--quota"} key={item.mid}>
                    <div className="row">
                        <div className="row start vcenter">
                            <div>
                                <span className="value">{$Quota.displayMarkQuota(item.quota)}</span> Anteile
                                an {markName}
                            </div>
                            {hasRelation && ICON}
                        </div>
                        <div className={"euro"}>{euro} EUR</div>
                    </div>
                </div>
            );
        }
        return (
            <div className={"customer--quota"} key={item.mid}>
                <div className="row">
                    <div className="row start vcenter">
                        <div>
                            <span className="value">{$Quota.displayMarkQuota(item.quota)}</span> Anteile an <a
                            onClick={() => onShowMark(item.mid)}>
                            {markName}</a> (+{item.more})
                        </div>
                        {hasRelation && ICON}
                    </div>
                    <div className={"euro"}>{euro} EUR</div>
                </div>
            </div>
        );
    }

    const drawActions = () => {
        if (!$user.canEdit) {
            return null;
        }

        return (
            <>
                <VSpace/>
                <a href={`/quota/${uid}/edit`}>Verwalten</a>
            </>
        );
    };

    return (
        <Card title={'Anteile'}>
            {
                keys.map((key: string) => (
                        <Fragment key={key}>
                            <strong>{key}</strong><br/>
                            {grouped[key].map(drawItem)}
                            <VSpace/>
                        </Fragment>
                    )
                )
            }
            {data.length === 0 && 'Keine Anteile zugewiesen.'}
            {drawTotalSum()}
            {drawActions()}
        </Card>
    );
}
