import {FC, ReactElement, useRef, useState} from "react";
import {Card} from "../../../components/UI/card/Card";
import {PrimaryButton} from "../../../components/UI/button/Button";
import {VSpace} from "../../../components/UI/spacer/Spacer";
import {useCollectPartnerInfoQuery, useDeletePartnerMutation} from "../../../configure/api/businesspartner-api";
import {Input} from "../../../components/UI/input/Input";

type props = {
    id: number,
    onDeleted?: () => void
};

export const PartnerDelete: FC<props> = ({id, onDeleted}): ReactElement | null => {

    const [confirm, setConfirm] = useState<string>('');
    const [$del] = useDeletePartnerMutation();
    const $collect = useCollectPartnerInfoQuery(id, {skip: id === -1});

    const code = useRef((Math.random() + 1).toString(36).substring(2));

    const onApply = () => {
        $del(id).unwrap().then(onDeleted).catch(() => {
        });
    };

    // last check for usufruct
    if ($collect?.data?.usufruct) {
        const {external, self} = $collect.data.usufruct;
        if (external?.length > 0 || self?.length > 0) {
            return null;
        }
    }

    return (
        <Card title={'Geschäftspartner löschen'}>
            <p>Dieser Vorgang kann nicht rückgängig gemacht werden. Bitte zur Bestätigung den
                Code <strong>{code.current}</strong> in das Feld unten eintragen.</p>
            <VSpace/>
            <Input initialValue={confirm} onChange={setConfirm} label={"Bestätigungscode"}/>
            <VSpace/>
            {code.current === confirm && <PrimaryButton label={'Unwiderruflich löschen'} onClick={onApply}/>}
        </Card>
    );
}
