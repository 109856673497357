import {FC, ReactElement} from "react";
import {$String} from "../../../utils/string";
import './MultilineInput.css';

type props = {
    initialValue?: string,
    label?: string,
    onChange?: (val: string) => void,
    onPaste?: (event: any) => void,
    rows?: number
};

export const MultilineInput: FC<props> = ({
                                              label = '',
                                              initialValue = '',
                                              onChange = () => null,
                                              onPaste = () => null,
                                              rows = 5,
                                          }): ReactElement => {

    return (
        <div className={`multilineinput ${initialValue.length > 0 || !label ? 'filled' : ''}`}>
            {label && <label>{label}</label>}
            <textarea onInput={(ev) => {
                // @ts-ignore
                onChange($String.removeDangerous(ev.target.value));
            }}
                      aria-label={'Mehrzeiliger Text für die Eigenschaft ' + label}
                      rows={rows}
            value={$String.replacePseudo(initialValue)}
            onPaste={onPaste}/>
        </div>
    );
}
