import {FC, ReactElement, useEffect, useState} from "react";
import {UserData} from "../../../models/user.data";
import {useSelector} from "react-redux";
import {selectUser} from "../../../configure/slice/auth-slice";
import {$PartnerData, PartnerCompany, PartnerData, PartnerMaerkner} from "../../../models/partner.data";
import {
    useUpdatePartnerMutation
} from "../../../configure/api/businesspartner-api";
import {FieldsData} from "../../../models/fields.data";
import {Card} from "../../../components/UI/card/Card";
import {VSpace} from "../../../components/UI/spacer/Spacer";
import {$String} from "../../../utils/string";
import {Tag} from "../../../components/UI/tag/Tag";
import {Store} from "react-notifications-component";
import {FormFields} from "../../../components/form-fields/FormFields";

type props = {
    data: PartnerData,
    section: 'person' | 'note' | 'contact' | 'heritage'
};

export const PartnerDetailsEdit: FC<props> = ({data, section}): ReactElement => {
    const $user: UserData = useSelector(selectUser);
    const [edit, setEdit] = useState<boolean>(false);
    const [customer, setCustomer] = useState<PartnerData>($PartnerData.create(data));
    const [renderData, setRenderData] = useState<{ fields: FieldsData[], title: string, attributes: string, render: any }>();

    const [$update] = useUpdatePartnerMutation();

    /**
     * draw tag
     * @param title
     * @param value
     * @param type
     * @param link
     */
    const drawTag = (title: string, value: string | undefined, type: any, link: string | undefined = undefined) => {
        if (value) {
            return (<div className={'customer--tags-item'}>
                <Tag type={type}>{title}</Tag>&nbsp;{link ? <a
                href={`${link}: ${value}`}>{value}</a> : <span>{value}</span>}
            </div>);
        }
        return null;
    };


    /**
     * render routine for person data
     */
    const renderPerson = () => {
        const salut = $PartnerData.isMaerkner(data) ? $String.combine(data, 'salutation title') : null;
        const name = $String.combine(data, $PartnerData.isMaerkner(data) ? 'firstname lastname' : 'name');

        return (
            <Card title={'Persönliche Daten'} onEdit={$user.canEdit ? () => setEdit(true) : undefined}>
                {salut}
                <div className="customer--name">{name}</div>
                <VSpace value={0.5}/>
                {$String.combine(data, 'street')}<br/>
                {$String.combine(data, 'zip city')}<br/>
                <VSpace/>

                <div className="customer--tags">
                    {$PartnerData.isMaerkner(data) && drawTag('Brief', (data as PartnerMaerkner).letter || '---', 'outline')}
                    {drawTag('UstId', data.taxid || '---', 'outline')}
                </div>
                <VSpace/>
                <a target='_blank'
                   rel="noreferrer"
                   href={$PartnerData.mapLink(data)}>Karte anzeigen</a>
            </Card>
        );
    };

    /**
     * render routine for person contact
     */
    const renderContact = () => (
        <Card title={'Kontakt'} onEdit={$user.canEdit ? () => setEdit(true) : undefined}>
            {$PartnerData.hasContact(data) ?
                <div className="customer--tags">
                    {
                        $PartnerData.isMaerkner(data) ?
                            <>
                                {drawTag('Telefon', (data as PartnerMaerkner)?.phone, 'primary', 'tel')}
                                {drawTag('Mobil', (data as PartnerMaerkner)?.phone_mobile, 'primary', 'tel')}
                                {drawTag('Fax', (data as PartnerMaerkner)?.phone_fax, 'primary', 'tel')}
                                {drawTag('EMail', (data as PartnerMaerkner)?.email, 'black', 'mailto')}
                            </> :
                            <>
                                {drawTag('Webseite', (data as PartnerCompany)?.website, 'primary')}
                            </>
                    }
                </div>
                : <span>---</span>
            }
        </Card>
    );

    /**
     * render routine for note
     */
    const renderNote = () => (
        <Card title={'Notiz'} onEdit={$user.canEdit ? () => setEdit(true) : undefined}>
            <div
                dangerouslySetInnerHTML={{__html: $String.replacePseudo(data?.note || '---').replace(/\n/g, '<br/>')}}
            />
        </Card>
    );

    /**
     * render routine for heritage
     */
    const renderHeritage = () => {
        if (!$PartnerData.isMaerkner(data)) {
            return null;
        }
        return (
            <Card title="Erbengemeinschaft" onEdit={$user.canEdit ? () => setEdit(true) : undefined}>
                <div
                    dangerouslySetInnerHTML={{__html: $String.replacePseudo((data as PartnerMaerkner)?.heritage || '---').replace(/\n/g, '<br/>')}}
                />
            </Card>);
    };

    /**
     * listener for section changes. define the fields and render function
     */
    useEffect(() => {
        setCustomer($PartnerData.create(data));

        let fields = [];

        switch (section) {
            case 'person':
                fields = $PartnerData.isMaerkner(data) ? $PartnerData.fieldsPerson : $PartnerData.fieldsCompany;
                setRenderData({
                    title: 'Persönliche Daten',
                    fields,
                    attributes: fields.map(item => item.attribute).join(' '),
                    render: renderPerson
                });
                break;
            case 'contact':
                fields = $PartnerData.isMaerkner(data) ? $PartnerData.fieldsContact : $PartnerData.fieldsContactCompany;
                setRenderData({
                    title: 'Kontakt',
                    fields,
                    attributes: fields.map(item => item.attribute).join(' '),
                    render: renderContact
                });
                break;
            case 'note':
                setRenderData({
                    fields: $PartnerData.fieldsNote,
                    title: 'Notiz',
                    attributes: 'note',
                    render: renderNote
                });
                break;
            case 'heritage':
                setRenderData({
                    fields: $PartnerData.fieldsHeritage,
                    title: 'Erbengemeinschaft',
                    attributes: 'heritage',
                    render: renderHeritage
                });
                break;
        }
    }, [data, section]);

    if (edit) {
        return (
            <Card title={renderData?.title}>
                <FormFields fields={renderData?.fields ?? []} data={customer} onUpdate={(key: string, value: any) => {
                    setCustomer({
                        ...customer,
                        [key]: value
                    });
                }} onSubmit={(flag) => {
                    if (flag) {
                        $update({attributes: renderData?.attributes || '', data: customer})
                            .unwrap()
                            .then(() => setEdit(false))
                            .catch(() => {
                                Store.addNotification({
                                    message: 'Daten konnten nicht aktualisiert werden.',
                                    type: "danger",
                                    container: "top-right",
                                    dismiss: {
                                        duration: 4000,
                                    }
                                });
                            });
                    } else {
                        setEdit(false);
                    }
                }}/>
            </Card>
        );
    }

    const drawContent = () => {
        return renderData?.render();
    };

    return drawContent();
};


